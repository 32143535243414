/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./Invoice.css";
import useApi from "../../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../../utils/secure-route/AuthContext";
import ATMDepositTable from "./ATMDepositTable";

function AtmDeposit() {
  const { user } = useContext(AuthContext);
  const { Get, Post } = useApi();
  const storeid = user.store;
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0]; // Formats date as YYYY-MM-DD

  const [isOpen, setIsOpen] = useState(false);
  const [banks, setBanks] = useState([]);

  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [reloadData, setReloadData] = useState(false);
  const [formData, setFormData] = useState({
    date: "" || today,
    transaction_type: "ATM Deposit",
    bank: "",
    amount: "",
    cash_amount: "",
    cheque_amount: "",
    total_amount: "",
    note: "",
    atm_commission: "",
    income_type: null,
    bank_deposite_type: null,
    owner: null,
  });



  const makeBlank = () => {
    setFormData({
      date: "" || today,
      transaction_type: "ATM Deposit",
      bank: "",
      amount: "",
      cash_amount: "",
      cheque_amount: "",
      total_amount: "",
      note: "",
      atm_commission: "",
      income_type: null,
      bank_deposite_type: null,
      owner: null,
    });
  };

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const data = await Get("bank");
        setBanks(data);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };
    fetchBanks();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (path) => {
    setIsOpen(false); // Close the dropdown after clicking a link
    navigate(path); // Navigate to the clicked path
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the selected type
    if (!formData.bank_deposite_type || formData.bank_deposite_type === "") {
      toast.error("Please select a valid Deposite type."); // Show error toast
      return; // Stop the submission if validation fails
    }

    // Validate cash amount
    if (!formData.cash_amount || formData.cash_amount <= 0) {
      toast.error("Please enter a valid Cash amount."); // Show error toast for cash_amount
      return; // Stop the submission if validation fails
    }

    try {
      const updatedFormData = { ...formData, store: storeid };
      await Post("transactionData", updatedFormData)
        .then((resp) => {
          toast.success("Submission successful!");
          setReloadData(!reloadData);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 100);
          makeBlank();
        })
        .catch((error) => {
          setReloadData(!reloadData);
          console.error("Error submitting form:", error);
          toast.error("Submission failed: " + error.message);
        });
    } catch (error) {
      setReloadData(!reloadData);
      toast.error("Submission failed: " + error.message);
    }
  };

  return (
    <div className="dashboard-container">
      <ToastContainer />
      <div className="formcontainer">
        <div
          className="container"
          style={{
            backgroundColor: "white",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
          }}
        >
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between mb-4">
              <div className="dropdown" ref={dropdownRef}>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  onClick={toggleDropdown}
                >
                  Atm Deposit
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "10px" }}
                  >
                    <path
                      d="M8.07608 9.20648C7.97615 9.20775 7.87708 9.18722 7.78523 9.14622C7.69338 9.10521 7.61078 9.04463 7.54275 8.96839L0.685603 1.82553C0.380841 1.50807 0.380841 1.016 0.685603 0.698544C0.990365 0.381084 1.46275 0.381084 1.76751 0.698544L8.09132 7.28585L14.3999 0.714417C14.7046 0.396957 15.177 0.396957 15.4818 0.714417C15.7866 1.03188 15.7866 1.52394 15.4818 1.8414L8.62465 8.98426C8.47227 9.14299 8.27417 9.22235 8.09132 9.22235L8.07608 9.20648Z"
                      fill="#002300"
                    />
                  </svg>
                </button>
                {isOpen && (
                  <ul
                    className="dash-drop-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a
                        className="dash-drop-menu-item"
                        onClick={() => handleLinkClick("/")}
                      >
                        Add Invoice
                      </a>
                    </li>
                    <li>
                      <a
                        className="dash-drop-menu-item"
                        onClick={() => handleLinkClick("/payInvoice")}
                      >
                        Pay Invoice
                      </a>
                    </li>
                    <li>
                      <a
                        className="dash-drop-menu-item"
                        onClick={() => handleLinkClick("/bankDeposit")}
                      >
                        Bank Deposit
                      </a>
                    </li>
                    <li>
                      <a
                        className="dash-drop-menu-item"
                        onClick={() => handleLinkClick("/atmDeposit")}
                      >
                        ATM Deposit
                      </a>
                    </li>
                  </ul>
                )}
              </div>

              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-contained me-2"
                  onClick={() => navigate("/")}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>

            <div className="col-md-12 row mb-3">
              <div className="mb-3 col-md-4">
                <input
                  type="date"
                  className="form-control-borderless"
                  name="date"
                  value={formData.date || today}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3 col-md-4">
                <input
                  type="text"
                  className="form-control-borderless"
                  name="note"
                  value={formData.note}
                  onChange={handleChange}
                  placeholder="Notes"
                />
              </div>
            </div>

            <div className="col-md-12 row">
              <div className="mb-3 col-md-12" style={{ marginLeft: "10px" }}>
                <div className="d-flex flex-row">
                  Type: &nbsp;&nbsp;&nbsp;&nbsp;
                  {["Business cash", "Lottery cash", "Gas cash"].map((type) => (
                    <div className="form-check me-3" key={type}>
                      <input
                        className="form-check-input"
                        type="radio"
                        name="bank_deposite_type"
                        id={`type-${type.toLowerCase().replace(" ", "-")}`}
                        value={type}
                        checked={formData.bank_deposite_type === type}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`type-${type.toLowerCase().replace(" ", "-")}`}
                      >
                        {type}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              {/* <div className="mb-3 mt-2 col-md-4 ">
                <select
                  className="form-control-borderless"
                  name="bank"
                  value={formData.bank}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a bank</option>
                  {banks.map((bank) => (
                    <option key={bank.id} value={bank.id}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            <div
              className="form-group col-md-5 mt-3"
              style={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #f4f4f4",
                paddingBottom: "16px",
                height: "50px",
              }}
            >
              <div className="input-group">
                <span className="input-group-text" style={{ border: "none" }}>
                  USD
                </span>
                <input
                  type="number"
                  style={{ border: "none" }}
                  className="form-control-borderless"
                  name="cash_amount"
                  placeholder="Amount"
                  value={formData.cash_amount}
                  onChange={handleChange}
                  onInput={(e) => {
                    // Allow only numbers and a decimal point with two digits after it
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                      .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                      .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                  }}
                
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        className="container mt-5"
        style={{
          backgroundColor: "white",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        <ATMDepositTable transaction_type="ATM Deposit" reloadData={reloadData}/>
      </div>
    </div>
  );
}

export default AtmDeposit;
