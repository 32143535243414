/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import ReusableModal from "../ManageSettings/ReusableModal";
import { toast ,ToastContainer} from "react-toastify";
import "./Gas.css";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import SettingTable from "../ManageSettings/SettingTable";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import Swal from "sweetalert2";


function GasTaxSettings() {
  let { user } = useContext(AuthContext);
  const storeId = user.store;

  const [freightData, setFreightData] = useState([]);
  const { Get, Post, Put, Delete } = useApi();

  const [gasTypeData, setGasTypeData] = useState([]);
  const [gasType, setGasType] = useState("");
  const [taxName, setTaxName] = useState("");
  const [taxValue, setTaxValue] = useState("");
  const [gas, setGas] = useState([]);

  const [selectedGasTax, setSelectedGasTax] = useState(null);
  const [showTaxModal, setShowTaxModal] = useState(false);
  const [selectedFreight, setSelectedFreight] = useState(null); // New state for selected freight
  const [freightName, setFreightName] = useState(""); // New state for freight name
  const [showFreightModal, setShowFreightModal] = useState(false);

  // Function to show modal
  const handleShowTax = () => setShowTaxModal(true);

  // Function to close modal and reset state
  const handleCloseTax = () => {
    setTaxName("");
    setTaxValue("");
    setGasType("");
    setSelectedGasTax(null);
    setShowTaxModal(false);
  };

  const handleShowFreight = () => setShowFreightModal(true);

  // Function to close freight modal and reset state
  const handleCloseFreight = () => {
    setSelectedFreight(null);
    setShowFreightModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const gasTypeResponse = await Get("gasTypeData");
        setGasTypeData(gasTypeResponse);

        const taxResponse = await Get("gasTaxData");
        setGas(taxResponse);

        const freightResponse = await Get("gasFreightData"); // Adjust the endpoint as necessary
        setFreightData(freightResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "gas_type") {
      setGasType(value);
    }

    if (name === "tax_name") {
      setTaxName(value);
    }

    if (name === "tax_value") {
      let taxValue = parseFloat(value);

      if (taxValue > 100) {
        taxValue = 100;
      } else if (taxValue < 0) {
        taxValue = 0;
      }

      // Update the tax value in state
      setTaxValue(taxValue);
    }

    if (name === "freight_name") {
      setFreightName(value);
    }

    if (name === "freight_value" && selectedFreight) {
      setSelectedFreight({ ...selectedFreight, freight_value: value });
    }
  };

  // Handle when user wants to edit an existing gas tax
  const handleEditGasTax = (gasTax) => {
    setSelectedGasTax(gasTax);
    setGasType(gasTax.gas_type);
    setTaxName(gasTax.tax_name);
    setTaxValue(gasTax.tax_value);
    handleShowTax(); // Show modal for editing
  };

  // Handle form submit (both add and edit)
  const handleSubmit = async (e) => {
    e.preventDefault();
    const gasTax = {
      gas_type: gasType,
      tax_name: taxName,
      tax_value: taxValue,
      store: storeId,
    };

    try {
      if (selectedGasTax) {
        // Update existing gas tax
        await Put("gasTaxData", selectedGasTax.id, gasTax);
        setGas((prevData) =>
          prevData.map((item) =>
            item.id === selectedGasTax.id ? { ...item, ...gasTax } : item
          )
        );
        toast.success("Tax data updated successfully!");
      } else {
        // Add new gas tax
        const response = await Post("gasTaxData", gasTax);
        setGas((prevData) => [...prevData, response.data]);
        toast.success("Tax data added successfully!");
      }
      handleCloseTax(); // Close modal after submission
    } catch (error) {
      console.error("Error posting gas type data:", error);
      toast.error("Error adding/updating gas type data.");
    }
  };

  // Handle delete action for a gas tax
  const handleDeleteGasTax = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
  });

  // Check if the user clicked the confirm button
  if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("gasTaxData", id);
      setGas((prevGasTax) => prevGasTax.filter((gasTax) => gasTax.id !== id));
      toast.success("Gas Commission deleted successfully");
    } catch (error) {
      console.error("Error deleting gas commission:", error);
      toast.error("Error deleting gas commission");
    }
  };

  const handleEditFreight = (freight) => {
    setSelectedFreight(freight);
    setFreightName(freight.freight_name); // Set freight name for editing
    handleShowFreight(); // Show modal for editing freight value
  };
  const handleSubmitFreight = async (e) => {
    e.preventDefault();
    const updatedFreight = {
      ...selectedFreight,
      freight_name: freightName, // Include freight name
      store: storeId,
    };

    try {
      await Put("gasFreightData", selectedFreight.id, updatedFreight);
      setFreightData((prevData) =>
        prevData.map((item) =>
          item.id === selectedFreight.id ? { ...item, ...updatedFreight } : item
        )
      );
      toast.success("Freight data updated successfully!");
      handleCloseFreight();
    } catch (error) {
      console.error("Error updating freight data:", error);
      toast.error("Error updating freight data.");
    }
  };

  // Columns for setting table
  const columns = [
    { header: "Tax Name", field: "tax_name" },
    { header: "Tax Value", field: "tax_value" },
    { header: "Gas Type", field: "gas_type" },
  ];

  const freightColumns = [
    { header: "Freight Name", field: "freight_name" },
    { header: "Freight Value", field: "freight_value" },
  ];

  return (
    <div className="due-days">
      <ToastContainer/>
      <div className="header-row">
        <div className="setting-title">Gas Tax Settings</div>
        <button className="gas-invoice-add-button" onClick={handleShowTax}>
          + Add Tax
        </button>
      </div>

      <ReusableModal
        show={showTaxModal}
        handleClose={handleCloseTax}
        title={selectedGasTax ? "Edit Gas Tax" : "Add Gas Tax"}
        width="1054px"
        height="auto"
      >
        <form onSubmit={handleSubmit}>
          <div className="form-group d-flex align-items-center mt-0">
            <input
              type="text"
              id="taxName"
              name="tax_name"
              value={taxName}
              placeholder="Tax Name"
              onChange={handleChange}
              className="input-field"
              required
              style={{ width: "260px", marginRight: "16px", height: "44px" }}
            />

            <input
              type="number"
              id="taxValue"
              name="tax_value"
              placeholder="Tax Value"
              value={taxValue}
              onChange={handleChange}
              className="input-field"
              required
              style={{ width: "260px", marginRight: "16px", height: "44px" }}
            />

            <select
              className="input-field"
              name="gas_type"
              value={gasType}
              onChange={handleChange}
              required
              style={{ width: "400px", marginRight: "16px", height: "44px" }}
            >
              <option value="">Select Gas Type</option>
              <option value="diesel">Diesel</option>
              <option value="gas">Gas</option>
              <option value="both">Both</option>
            </select>

            <button
              type="submit"
              className="submit-btn"
              style={{
                padding: "6px 8px",
                backgroundColor: "#4545db",
                color: "white",
                border: "none",
                borderRadius: "40px",
                cursor: "pointer",
                width: "124px",
                height: "42px",
                fontSize: "18px",
                marginLeft: "16px",
              }}
            >
              {selectedGasTax ? "Update" : "Submit"}
            </button>
          </div>
        </form>
      </ReusableModal>

      <SettingTable
        data={gas}
        columns={columns}
        showFooter={true}
        showAction={true}
        onEdit={handleEditGasTax}
        onDelete={handleDeleteGasTax}
      />

      <ReusableModal
        show={showFreightModal}
        handleClose={handleCloseFreight}
        title="Edit Freight Data"
        width="600px"
        height="auto"
      >
        <form onSubmit={handleSubmitFreight}>
          <div className="form-group d-flex align-items-center mt-0">
            <input
              type="text"
              id="freightName"
              name="freight_name"
              value={freightName}
              onChange={handleChange}
              className="input-field"
              required
              readOnly
              placeholder="Freight Name"
              style={{ width: "260px", marginRight: "16px", height: "44px" }}
            />
            <input
              type="number"
              name="freight_value"
              value={selectedFreight ? selectedFreight.freight_value : ""}
              onChange={handleChange}
              placeholder="Freight Value"
              className="input-field"
              required
              style={{ width: "260px", marginRight: "16px", height: "44px" }}
            />

            <button
              type="submit"
              className="submit-btn"
              style={{
                padding: "6px 8px",
                backgroundColor: "#4545db",
                color: "white",
                border: "none",
                borderRadius: "40px",
                cursor: "pointer",
                width: "124px",
                height: "42px",
                fontSize: "18px",
                marginLeft: "16px",
              }}
            >
              Update
            </button>
          </div>
        </form>
      </ReusableModal>
      <div className="header-row">
        <div className="setting-title">Gas Freight Settings</div>
      </div>
      <SettingTable
        data={freightData}
        columns={freightColumns}
        showFooter={true}
        showAction={true}
        onEdit={handleEditFreight}
      />
    </div>
  );
}

export default GasTaxSettings;
