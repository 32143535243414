import React, { useState } from "react";
import "../ManageSettings/Settings.css"; // Custom styles for the table

const DataTable = ({
  data,
  columns,
  showAction = true,
  onEdit,
  onDelete,
  showFooter = true,
  togglePaymentModal
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  // Sorting logic
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // Get the displayed data for the current page
  const getDisplayedData = () => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return data.slice(startIndex, endIndex);
  };

  // Sorting the data based on the selected column
  const sortData = (dataToSort) => {
    if (sortConfig.key) {
      return [...dataToSort].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return dataToSort;
  };

  const displayedData = getDisplayedData();
  const sortedData = sortData(displayedData);

  // Pagination
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage === i ? "active" : ""}`}>
          <a
            className="page-link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCurrentPage(i);
            }}
          >
            {i}
          </a>
        </li>
      );
    }
    return pages;
  };

  return (
    <div className="data-table-container">
      <table className="data-table custom-table rounded-table">
        <thead className="table-header">
          <tr>
            {columns.map((col, index) => (
              <th
                key={index}
                scope="col"
                onClick={() => handleSort(col.field)}
                style={{ cursor: "pointer" }}
              >
                {col.header}
                {sortConfig.key === col.field && (
                  sortConfig.direction === "ascending" ? (
                    <svg
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginLeft: "5px" }}
                    >
                      <path d="M0 4L4 0L8 4H0Z" fill="#002300" />
                    </svg>
                  ) : (
                    <svg
                      width="8"
                      height="4"
                      viewBox="0 0 8 4"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ marginLeft: "5px" }}
                    >
                      <path d="M0 0L4 4L8 0H0Z" fill="#002300" />
                    </svg>
                  )
                )}
              </th>
            ))}
            {showAction && <th scope="col" className="action-column">Actions</th>}
          </tr>
        </thead>
        <tbody className="table-body">
          {sortedData.length === 0 ? (
            <tr>
              <td colSpan={columns.length + (showAction ? 2 : 1)} style={{ textAlign: "center", fontFamily: 'Manrope',
          fontWeight: '600',
          fontSize: '40px',
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.1) -92.86%, #4545DB 71.43%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent', }}>
                No data found
              </td>
            </tr>
          ) : (
            sortedData.map((item, index) => (
              <tr key={item.id || index}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>
                    {col.field === "status" ? (
                      <div className={`status ${item[col.field]}`}>{item[col.field]}</div>
                    ) : col.field === "pay_salary" ? (
                      <button
                        className="btn btn-primary"
                        onClick={() => togglePaymentModal(item)} // Pass item to payment modal
                      >
                        Pay Salary
                      </button>
                    ) : (
                      item[col.field]
                    )}
                  </td>
                ))}
                {showAction && (
                  <td className="action-column">
                    <button className="action-button" onClick={() => onEdit(item.id)}>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.8067 4.695C14.0667 4.435 14.0667 4.00167 13.8067 3.755L12.2467 2.195C12 1.935 11.5667 1.935 11.3067 2.195L10.08 3.415L12.58 5.915M2 11.5017V14.0017H4.5L11.8733 6.62167L9.37333 4.12167L2 11.5017Z"
                          fill="#002300"
                        />
                      </svg>
                    </button>
                    <button className="action-button" onClick={() => onDelete(item.id)}>
                      {/* Delete icon */}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.00016 12.6667C4.00016 13.4 4.60016 14 5.3335 14H10.6668C11.4002 14 12.0002 13.4 12.0002 12.6667V6C12.0002 5.26667 11.4002 4.66667 10.6668 4.66667H5.3335C4.60016 4.66667 4.00016 5.26667 4.00016 6V12.6667ZM12.0002 2.66667H10.3335L9.86016 2.19333C9.74016 2.07333 9.56683 2 9.3935 2H6.60683C6.4335 2 6.26016 2.07333 6.14016 2.19333L5.66683 2.66667H4.00016C3.6335 2.66667 3.3335 2.96667 3.3335 3.33333C3.3335 3.7 3.6335 4 4.00016 4H12.0002C12.3668 4 12.6668 3.7 12.6668 3.33333C12.6668 2.96667 12.3668 2.66667 12.0002 2.66667Z"
                          fill="#002300"
                        />
                      </svg>
                    </button>
                  </td>
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>

      {showFooter && (
        <nav>
          <ul className="pagination">
            <li className={`prev-next ${currentPage === 1 ? "disabled" : ""}`}>
              <span
                style={{
                  fontSize: "24px",
                  width: "40px",
                  height: "40px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "50%",
                  border: "1px solid #bfbfbfbf",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={handlePrev}
              >
                {"<"}
                &lt;
              </span>
              &nbsp;
            </li>
            {renderPagination()}
            <li className={`prev-next ${currentPage === totalPages ? "disabled" : ""}`}>
              <span
                style={{
                  fontSize: "24px",
                  width: "40px",
                  height: "40px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  borderRadius: "50%",
                  border: "1px solid #bfbfbfbf",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  cursor: "pointer",
                }}
                onClick={handleNext}
              >
                {">"}
              </span>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
};

export default DataTable;
