import React from "react";
import PropTypes from "prop-types";
import backgroundimg from "../../assets/img/BackgroundBody.png";
import { Link } from "react-router-dom";
import "./Formlayout.css";
const FormLayout = ({ logoSrc, backgroundImageSrc, children, TopImage }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        padding: "20px",
        boxSizing: "border-box",  
        zIndex: "99999",
        backgroundSize: "cover",
        backgroundPosition: "center", 
        backgroundRepeat: "no-repeat", 
          backgroundImage: `url('${backgroundimg}')`,
      }}
    >
      <div
        style={{
          display: "flex",
          height: "860px",
          width: "1440px",
          borderRadius: "30px",
          overflow: "hidden",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#ffffff",
          flexDirection: "row",
          "@media (maxWidth: 768px)": {
            flexDirection: "column",
          },
        }}
      >
        {/* Left side: Form */}
        <div
          style={{
            flex: "1",
            maxWidth: "612px",
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            boxSizing: "border-box",
            height: "100%",
            overflow: "hidden",
            position: "relative",
            "@media (maxWidth: 768px)": {
              width: "100%",
              maxWidth: "none",
            },
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "30px",
              left: "70px",
            }}
          >
            <Link to="/login">
              <img
                src={logoSrc}
                alt="Logo"
                style={{ height: "70px", width: "100px" }}
              />

            </Link>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {children} {/* Render the form or other content here */}
          </div>
        </div>
        {/* Right side: Background image */}
        <div
          style={{
            flex: "1",
            backgroundColor: "#DADAF8",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            position: "relative",
            height: "860px", // Updated height
            width: "827px", // Updated width
            "@media (maxWidth: 768px)": {
              display: "none",
            },
          }}
          className="hideonmobile"
        >
          <img
            src={TopImage}
            alt="Background"
            style={{
              width: "461.13px",
              height: "120px",
              objectFit: "contain",
              position: "absolute",
              top: 50,
              right: 0,
              left: 220,
            }}
          />
          <img
            src={backgroundImageSrc}
            alt="Background"
            style={{
              width: "100%",
              objectFit: "contain",
              position: "absolute",
              top: 230,
              right: 0,
              left: 80,
            }}
          />
        </div>
      </div>
    </div>
  );
};
FormLayout.propTypes = {
  logoSrc: PropTypes.string.isRequired,
  backgroundImageSrc: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  TopImage: PropTypes.string.isRequired,
};
export default FormLayout;
