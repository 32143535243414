/* eslint-disable no-useless-computed-key */
/* eslint-disable no-useless-rename */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Report.css";
import { Email, LottoIcon } from "../../../../utils/api-manager/Forms/SvgIcons";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import PrintReport from "../Reports/PrintReport";
import ReusableModal2 from "../ManageSettings/ReusableModal2";
import { toast, ToastContainer } from "react-toastify";
import LotteryReportTable from "../Lottery/LotteryReportTable";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import InvoiceForm from "../Dashboard/Invoice/InvoiceForm";
import AuthContext from "../../../../utils/secure-route/AuthContext";

function BusinessReport() {
  const { Post, Patch, Get } = useApi();
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];

  const [shifts, setShifts] = useState([]);
  const [selectedShift, setSelectedShift] = useState("");
  const [shiftDetails, setShiftDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(today);

  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState("");
  const [invoiceFields, setInvoiceFields] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [payrollData, setPayrollData] = useState([]);
  const [selectedPayroll, setSelectedPayroll] = useState(null);
  const [printReport, SetprintReport] = useState();
  const [shift_Id, setShift_Id] = useState();
  const [scanNumber, setScanNumber] = useState("");
  const [reloadOnScan, setReloadOnScan] = useState(false);
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const handleAddInvoiceClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  // const handleInvoiceChange = (e) => {
  //   const value = e.target.value;
  //   setSelectedInvoice(value);
  //   if (value === "addInvoice") {
  //     handleAddInvoiceClick();
  //   }
  // };

  const handlePayrollSelect = async (e) => {
    const payrollId = e.target.value;

    if (payrollId === "") return; // Do nothing if no selection is made

    const selectedPayrollData = payrollData.find(
      (payroll) => payroll.id === payrollId
    );

    setSelectedPayroll(selectedPayrollData);
  };
  useEffect(() => {



    console.log(shift_Id, "///////////////");
    const fetchPayrollData = async () => {
      try {
        const response = await Get("payrollSalaryData");
        if (response && Array.isArray(response)) {
          setPayrollData(response);
          setSelectedPayroll(null)
        }

      } catch (error) {
        console.error("Error fetching payroll data:", error);
      }
    };

    fetchPayrollData();
  }, []);

  function getTicketWithGameAndBook(input) {
    // Convert the input to a string
    let str = input.toString().slice(0, 14);
    // Extract parts of the string
    let start = str.slice(0, 3); // First 3 digits
    let middle = str.slice(4, -4); // Middle part (excluding first 3 and last 3 digits)
    let end = str.slice(-4).slice(0, 3); // Last 3 digits
    // Combine with "-" separators
    let obj = {
      gameNo: start,
      bookNo: middle,
      ticketNo: end,
    };
    return obj;
  }
  // Update the payroll amount change handler to call API directly
  const handlePayrollAmountChange = async (e, payroll) => {
    const newAmount = e.target.value;
    const payload = {
      business_report_id: shiftDetails.business_report?.id,
      type: "other",
      title_id: payroll.payroll_employee_name,
      amount: parseFloat(newAmount),
      object_id: payroll.id,
    };

    await Post("purchaseExpenseAndOtherBusinessData", payload)
      .then((resp) => {
        refetchShiftData();
        setSelectedPayroll(null); // or set it to an appropriate default value
      })
      .catch((error) => {
        console.error("Error updating payroll amount:", error);
      });
  };

  const handleEmailButtonClick = () => {
    setShowEmailModal(true);
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        shift_id: shiftDetails.shift?.id,
      };

      const response = await Post("emailDailyReport", payload);

      if (response.status === 200) {
        // Success handling
        alert("Report sent successfully");
        setShowEmailModal(false);
      } else {
        // Error handling
        alert("Failed to send report");
      }
    } catch (error) {
      console.error("Error sending email report:", error);
      alert("An error occurred while sending the report");
    }
  };

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  // Fetch invoices effect
  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        const response = await Get("invoiceData");

        if (response && Array.isArray(response)) {
          // Store all invoices
          setInvoices(response);

          // Filter invoices for today
          const todayDate = getTodayDate();
          const todayInvoices = response.filter((invoice) => {
            const invoiceDate = new Date(invoice.created_at)
              .toISOString()
              .split("T")[0];
            return invoiceDate === todayDate;
          });

          setFilteredInvoices(todayInvoices);
        }
      } catch (error) {
        console.error("Error fetching invoice data:", error);
      }
    };

    fetchInvoiceData();
  }, []);


  const handleInvoiceSelect = (e) => {
    const invoiceId = e.target.value;

    if (invoiceId === "addInvoice") {
      handleAddInvoiceClick();
      return;
    }

    // Check if the invoice is already in the purchase expenses of the current shift
    const isInvoiceAlreadyAdded = shiftDetails.purchase_expenses?.some(
      expense => expense.object_id === invoiceId && expense.type === 'purchase_expense'
    );

    if (isInvoiceAlreadyAdded) {
      toast.error("This invoice has already been added to the shift report");
      return;
    }

    // Use optional chaining and nullish coalescing for safer access
    const selectedInvoiceData = filteredInvoices.find(inv => inv.id === invoiceId);

    if (!selectedInvoiceData) {
      toast.error("Invalid invoice selected");
      return;
    }

    if (invoiceFields.some(field => field.invoiceId === invoiceId)) {
      toast.error("This invoice has already been selected");
      return;
    }

    setSelectedInvoice(invoiceId);
    setInvoiceFields(prev => [
      ...prev,
      {
        id: Date.now(),
        invoiceId,
        invoiceNumber: selectedInvoiceData?.invoice_number ?? "",
        vendorDepartment: selectedInvoiceData?.vendor_department_name ?? "",
        amount: selectedInvoiceData?.total_amount ?? "",
        originalAmount: selectedInvoiceData?.amount ?? "",
      }
    ]);

    // Reset the select to default
    e.target.value = "";
  };


  const handleInvoiceFieldChange = async (id, fieldName, value) => {
    const updatedFields = invoiceFields.map((field) =>
      field.id === id ? { ...field, [fieldName]: value } : field
    );
    setInvoiceFields(updatedFields);

    // Retrieve the invoiceId from updatedFields
    const invoiceId = updatedFields.find((field) => field.id === id)?.invoiceId;
    const invoice = filteredInvoices.find((invoice) => invoice.id === invoiceId);

    console.log(invoice?.invoice_no);

    if (invoice) {
      if (fieldName === "amount") {
        try {
          // Validate the amount is a valid number
          const parsedAmount = parseFloat(value);
          if (isNaN(parsedAmount)) {
            toast.error('Invalid amount entered');
            return;
          }

          const payload = {
            business_report_id: shiftDetails.business_report?.id,
            type: "purchase_expense",
            title_id: updatedFields.find((f) => f.id === id).vendorDepartment,
            amount: parsedAmount,
            invoice_no: invoice.invoice_no,
            object_id: invoiceId,
          };

          const response = await Post("purchaseExpenseAndOtherBusinessData", payload);

          if (response.status === 200) {
            toast.success('Invoice Amount Updated');
            refetchShiftData();
          }
        } catch (error) {
          console.error("Error updating invoice amount:", error);
          toast.error(`Update failed: ${error.message || 'Unknown error'}`);
          // Revert to original amount instead of clearing fields
          const revertedFields = invoiceFields.map((f) =>
            f.id === id ? { ...f, amount: f.originalAmount } : f
          );
          setInvoiceFields(revertedFields);
        }
      }
    }
  };
  const removeInvoiceField = (id) => {
    setInvoiceFields(invoiceFields.filter((field) => field.id !== id));
  };

  const calculateTotalInvoiceAmount = () => {
    return invoiceFields.reduce((total, field) => {
      const amount = parseFloat(field.amount) || 0;
      return total + amount;
    }, 0);
  };

  const handleRegisterField = async (registerNo, field, value) => {
    const payload = {
      business_report: shiftDetails.business_report?.id,
      register_no: registerNo,
      [field]: value,
    };

    await Post("updateRegisterAndBusinessReport", payload)
      .then((response) => {
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          register_net_sales: prevDetails.register_net_sales.map((register) => {
            if (register.register_no === registerNo) {
              return {
                ...register,
                [field]: value,
              };
            }
            return register;
          }),
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error(
          `Error updating ${field} for register ${registerNo}:`,
          error
        );
      });
  };

  const handleGlobalField = async (field, value) => {
    const businessReportId = shiftDetails.business_report?.id;

    // Check if the business report ID exists, if not log an error and exit the function
    if (!businessReportId) {
      console.error("Business report ID is missing.");
      return;
    }

    const payload = {
      business_report: businessReportId,
      [field]: value,
    };

    const response = await Post("updateRegisterAndBusinessReport", payload)
      .then((response) => {
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          business_report: {
            ...prevDetails.business_report,
            [field]: value,
          },
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error("Error Updating Field", error);
      });
  };

  // Combined handler that routes to the appropriate function
  const handleFieldChange = (
    registerNo,
    field,
    value,
    isRegisterField = true
  ) => {
    if (isRegisterField) {
      handleRegisterField(registerNo, field, value);
    } else {
      handleGlobalField(field, value);
    }
  };
  const handleLotteryRegisterField = async (registerNo, field, value) => {
    const payload = {
      lottery_report: shiftDetails.lottery_report?.id,
      register_no: registerNo,
      [field]: value,
    };

    await Post("updateLotteryReport", payload)
      .then((response) => {
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          lottery_register_reports: prevDetails.lottery_register_reports.map(
            (register) => {
              if (register.register_no === registerNo) {
                return {
                  ...register,
                  [field]: value,
                };
              }
              return register;
            }
          ),
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error(
          `Error updating ${field} for register ${registerNo}:`,
          error
        );
      });
  };

  const handleLotteryGlobalField = async (field, value) => {
    const payload = {
      lottery_report: shiftDetails.lottery_report?.id,
      [field]: value,
    };

    const response = await Post("updateLotteryReport", payload)
      .then((response) => {
        // Update local state for the global field
        setShiftDetails((prevDetails) => ({
          ...prevDetails,
          lottery_report: {
            ...prevDetails.lottery_report,
            [field]: value,
          },
        }));
        refetchShiftData();
      })
      .catch((error) => {
        console.error(`Error updating ${field}:`, error);
      });
  };

  const handleLotteryFieldChange = (
    registerNo,
    field,
    value,
    isRegisterField = true
  ) => {
    if (isRegisterField) {
      handleLotteryRegisterField(registerNo, field, value);
    } else {
      handleLotteryGlobalField(field, value);
    }
  };

  useEffect(() => {
    const fetchShifts = async () => {
      try {
        const response = await Post("getShiftDataWithRole", {
          date: selectedDate,
        });

        // Check if the response indicates no shifts found
        if (response?.message === "No shifts found for the provided date") {
          // Clear existing shifts
          setShifts([]);
          setShiftDetails({});
          setSelectedShift("");

          // Show toast notification
          toast.info("You have no shifts on this date", {
            position: "top-right",
            autoClose: 3000,
          });

          return; // Exit the function early
        }

        const shiftData = Array.isArray(response?.data?.shift_data)
          ? response.data.shift_data
          : [];

        const sortedShifts = shiftData.sort((a, b) => {
          const timeA = a.shift.start_time
            ? new Date(a.shift.start_time).getTime()
            : 0;
          const timeB = b.shift.start_time
            ? new Date(b.shift.start_time).getTime()
            : 0;
          return timeA - timeB;
        });

        setShifts(sortedShifts);

        if (sortedShifts.length > 0) {
          const userShift = sortedShifts.find(
            (shift) => shift.shift.user === user.user_id
          );

          const selectedShift =
            userShift || sortedShifts[sortedShifts.length - 1];

          setSelectedShift(selectedShift.shift.title);
          setShiftDetails(selectedShift);
          setShift_Id(selectedShift.shift.id);
          printDailyReport(selectedShift.shift.id);
        }
      } catch (error) {
        console.error("Error fetching shift data:", error);

        // Optional: Show a toast for general error
        toast.error("Error fetching shift data", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    };

    fetchShifts();
  }, [selectedDate, user.user_id]);

  const handleShiftChange = (e) => {
    const selectedTitle = e.target.value;
    setSelectedShift(selectedTitle);

    const selectedShiftDetails = shifts.find(
      (shift) => shift.shift.title === selectedTitle
    );

    if (selectedShiftDetails) {
      setShiftDetails(selectedShiftDetails);

      // Log specific details to verify
    } else {
      console.warn("No shift details found for selected title");
      setShiftDetails({}); // Ensure you reset to an empty object if no match
    }
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const handleModalShift = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleShiftEnd = async () => {
    try {
      const shiftId = shifts.find(
        (shift) => shift.shift.title === selectedShift
      )?.shift.id;
      if (shiftId) {
        const response = await Patch(`shiftData`, shiftId, {
          is_active: false,
        });
        if (response.status === 200) {
          navigate("/reportDateSelection");
        }
      }
    } catch (error) {
      console.error("Error ending shift:", error);
    } finally {
      setShowModal(false);
    }
  };
  const refetchShiftData = async () => {
    try {
      const response = await Post("getShiftDataWithRole", {
        date: selectedDate,
      });

      const shiftData = Array.isArray(response?.data?.shift_data)
        ? response.data.shift_data
        : [];

      const sortedShifts = shiftData.sort((a, b) => {
        const timeA = new Date(a.shift.start_time).getTime();
        const timeB = new Date(b.shift.start_time).getTime();
        return timeA - timeB;
      });

      // Update shifts state
      setShifts(sortedShifts);

      // Find the currently selected shift in the new shift data
      const currentlySelectedShiftDetails = sortedShifts.find(
        (shift) => shift.shift.title === selectedShift
      );

      if (currentlySelectedShiftDetails) {
        // If the currently selected shift exists in the new data, keep it
        setShiftDetails(currentlySelectedShiftDetails);
        setShift_Id(currentlySelectedShiftDetails.shift.id);
        printDailyReport(currentlySelectedShiftDetails.shift.id);
      } else if (sortedShifts.length > 0) {
        // Fallback to last shift or user shift if current shift not found
        const userShift = sortedShifts.find(
          (shift) => shift.shift.user === user.user_id
        );
        const fallbackShift =
          userShift || sortedShifts[sortedShifts.length - 1];

        setSelectedShift(fallbackShift.shift.title);
        setShiftDetails(fallbackShift);
        setShift_Id(fallbackShift.shift.id);
        printDailyReport(fallbackShift.shift.id);
      }

      // Reset invoice-related states
      const todayInvoices = invoices.filter((invoice) => {
        const invoiceDate = new Date(invoice.created_at)
          .toISOString()
          .split("T")[0];
        return invoiceDate === selectedDate;
      });
      setFilteredInvoices(todayInvoices);
      setInvoiceFields([]); // Clear invoice fields
      setSelectedInvoice("");
    } catch (error) {
      console.error("Error fetching updated shift data:", error);
    }
  };

  const printDailyReport = async (shiftId) => {
    let siftid = shiftId || shift_Id;
    await Post(`printDailyReport`, { shift_id: siftid }).then((response) => {
      SetprintReport(response.data);
      const data = response.data;
      localStorage.setItem("printDailyReport", JSON.stringify(data));
    }).catch((error)=>{
      console.log("error")
    });
  };
  const {
    business_report = {},
    lottery_report = {},
    gas_report = {},
    register_net_sales = [],
  } = shiftDetails;

  // Extracted values with defaults
  const {
    total_net_sales: totalNetSales = "N/A",
    total_net_taxes: totalNetTaxes = "N/A",
    gross_sales: grossSales = "N/A",
    refund = "N/A",
    cheque = "N/A",
    total_register_cash: totalRegisterCash = "N/A",
    cash_to_account: cashToAccount = "N/A",
    over_short: overShort = "N/A",
    credit_card: credit_card = "N/A",
    meal_tax = "N/A",
  } = business_report;

  /*==================================================================== Lottery===============================================================================================*/
  const [gameNo, setGameNo] = useState("");
  const [bookNo, setBookNo] = useState("");

  const handleGameNoChange = (e) => {
    const value = e.target.value;

    // Check if the value contains only numbers and has a maximum of 20 digits
    if (/^\d{0,20}$/.test(value)) {
      setGameNo(value);
    } else {
      // Optional: You can show an error message or alert the user
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleBookNoChange = (e) => {
    const value = e.target.value;

    // Check if the value contains only numbers and has a maximum of 20 digits
    if (/^\d{0,20}$/.test(value)) {
      setBookNo(value);
    } else {
      // Optional: You can show an error message or alert the user
      console.log("Only 20 numeric characters are allowed.");
    }
  };
    const [gameReturnNo, setGameReturnNo] = useState("");
  const [bookReturnNo, setBookReturnNo] = useState("");
  const [ticketNo, setTicketNo] = useState("");
  const handleGameReturnNoChange = (e) => {
    const value = e.target.value;

    // Check if the value contains only numbers and has a maximum of 20 digits
    if (/^\d{0,20}$/.test(value)) {
      setGameReturnNo(value);
    } else {
      // Optional: You can show an error message or alert the user
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleBookNoReturnChange = (e) => {
    const value = e.target.value;

    // Check if the value contains only numbers and has a maximum of 20 digits
    if (/^\d{0,20}$/.test(value)) {
      setBookReturnNo(value);
    } else {
      // Optional: You can show an error message or alert the user
      console.log("Only 20 numeric characters are allowed.");
    }
  };
  const handleTicketNoChange = (e) => {
    const value = e.target.value;

    // Check if the value contains only numbers and has a maximum of 20 digits
    if (/^\d{0,20}$/.test(value)) {
      setTicketNo(value);
    } else {
      // Optional: You can show an error message or alert the user
      console.log("Only 20 numeric characters are allowed.");
    }
  };
    const [reloadLottoTable, setReloadLottoTable] = useState(false);

  const handleActivate = async () => {
    const payload = {
      game_no: gameNo,
      book_no: bookNo,
      active_date: today,
      status: "activated",
      activated_book_shift: shift_Id,
    };


      const response = await Post("updateGameAndInventory", payload).then((response)=>{
        toast.success("Game activated successfully!");
        setGameNo("");        // Reset the game number
        setBookNo("");        // Reset the book number
        setScanNumber("");  // Reset the scan number
        setReloadLottoTable(prevState => !prevState); // Toggle the lotto table reload state
      }).catch((error)=>{
        if(error.response.status === 400){
          toast.warning(`Book is already activated, please try with different book number.`);
        }else{
          
          toast.error(`No game found in your inventory, kindly add game in inventory and try again.`);
        }
       
        setScanNumber("");    // Reset the scan number
        setReloadLottoTable(prevState => !prevState); // Toggle the lotto table reload state
      });


   
  };


  const handleDeleteInvoice = async (invoiceId) => {
    try {
      // Find the specific invoice/expense to delete from the current shift's purchase expenses
      const invoiceToDelete = shiftDetails.purchase_expenses.find(
        (expense) => expense.id === invoiceId
      );

      if (!invoiceToDelete) {
        toast.error("Invoice not found");
        return;
      }

      const payload = {
        business_report_id: shiftDetails.business_report?.id,
        object_id: invoiceToDelete.object_id,
        delete: "true",
      };

      await Post("purchaseExpenseAndOtherBusinessData", payload)
        .then((response) => {
          toast.success("Invoice deleted successfully!");

          // Update purchase expenses by filtering out the deleted entry
          const updatedPurchaseExpenses = shiftDetails.purchase_expenses.filter(
            (expense) => expense.id !== invoiceId
          );

          // Update the shift details state
          setShiftDetails((prevDetails) => ({
            ...prevDetails,
            purchase_expenses: updatedPurchaseExpenses,
            business_report: {
              ...prevDetails.business_report,
              total_purchase_expense: (
                parseFloat(prevDetails.business_report.total_purchase_expense) -
                parseFloat(invoiceToDelete.amount)
              ).toFixed(2),
            },
          }));
        })
        .catch((errpr) => {
          toast.error("Failed to delete invoice");
        });
    } catch (error) {
      console.error("Error deleting invoice:", error);
      toast.error("An error occurred while deleting the invoice");
    }
  };

  const handleDeletePayrollEntry = async (payrollId) => {
    try {
      // Create the payload for the API request
      const payload = {
        business_report_id: shiftDetails.business_report?.id,
        object_id: payrollId.object_id,
        delete: "true",
      };

      // Make the API POST request to delete the payroll entry
      const response = await Post(
        "purchaseExpenseAndOtherBusinessData",
        payload
      );

      // Ensure the response is valid and successful (status 200)
      if (response && response.status === 200) {
        toast.success("Payroll entry deleted successfully!");

        // Update payrollData state by filtering out the deleted payroll entry
        setPayrollData((prevPayrollData) =>
          prevPayrollData.filter((entry) => entry.id !== payrollId)
        );

        // Refetch shift data to ensure everything is in sync
        refetchShiftData();
      } else {
        toast.error("Failed to delete payroll entry");
      }
    } catch (error) {
      console.error("Error deleting payroll entry:", error);
      toast.error("An error occurred while deleting the payroll entry");
    }
  };

  const handleReturn = async () => {
    const today = new Date().toISOString().split("T")[0];

    try {
      const payload = {
        returned_book_shift: shift_Id,
        game_no: gameReturnNo,
        book_no: bookReturnNo,
        total_no_of_ticket_return: ticketNo,
        return_date: today,
        status: "returned",
      };

      // Log the payload to confirm it's correct before sending it
      console.log("Payload being sent:", payload);

      // Use only await, no need for .then()
      const response = await Post("updateGameAndInventory", payload);

      // Log the response to see if it's returning anything unexpected
      console.log("Response:", response);

      // Check if response and data exist
      if (response && response.data) {
        // Check if 'lotteryInventory' exists in response.data
        const returnedInventory = response.data.lotteryInventory;

        if (returnedInventory) {
          // Log the returned inventory to debug its structure
          console.log("Returned inventory:", returnedInventory);

          // Check if 'returned_book_shift' is properly set
          if (returnedInventory.returned_book_shift) {
            toast.success("Book returned successfully!");
            // Reset fields after successful return
            setGameReturnNo("");
            setBookReturnNo("");
            setTicketNo("");
            setScanNumber("");
            setReloadLottoTable(!reloadLottoTable);
          } else {
            // 'returned_book_shift' was not returned properly in the response
            console.error("Returned book shift was not updated properly on the backend.");
            toast.error("Returned book shift not updated properly.");
          }
        } else {
          // 'lotteryInventory' is missing from the response data
          console.error("Lottery inventory is missing from the response.");
          toast.error("Failed to return book. Lottery inventory missing.");
        }
      } else {
        // 'data' or the entire response is missing
        console.error("Response or data is missing.");
        toast.error("Failed to return book. Response missing or incorrect.");
      }

    } catch (error) {
      // Log any error that occurs during the request
      console.error("Error occurred while returning the book:", error);
      toast.error("There was an error returning the book.");
    }
  };



  const [activeTab, setActiveTab] = useState("activated"); // Ensure it starts on "activated"
  const [activatedBooks, setActivatedBooks] = useState([]);
  const [returnedBooks, setReturnedBooks] = useState([]);

  // Define columns for each table
  const activatedColumns = [
    { header: "Game Number", field: "game_no" },
    { header: "Book Number", field: "book_no" },
    { header: "Status", field: "status" },
  ];

  const returnedColumns = [
    { header: "Game Number", field: "game_no" },
    { header: "Book Number", field: "book_no" },
    { header: "Ticket Number", field: "total_no_of_ticket_return" },
    { header: "Status", field: "status" },
  ];

  const [lastTicketNo, setLastTicketNo] = useState(0);
  // Fetch data from API
  useEffect(() => {
    const fetchData = async (shift_id) => {
      try {
        const response = await Get("lotteryInventoryData");

        // Filter data based on status
        const activated = response.filter((item) => item.status === "activated");
        const returned = response.filter((item) => item.status === "returned");
        const scanned = response.filter((item) => item.status === "activated");


        // Update state
        setActivatedBooks(activated);
        setReturnedBooks(returned);
        setScannedTickets(scanned);
        printDailyReport(shift_id);
       console.log(shift_id)
      } catch (error) {
        console.error("Error fetching lottery inventory data:", error);
      }
    };

    // Always fetch data when component mounts
    fetchData(shift_Id);
  }, [reloadOnScan, reloadLottoTable]);

  const handleTabClick = (tab) => {
    setReloadLottoTable(!reloadLottoTable);
    if (tab !== activeTab) {
      setActiveTab(tab);
    }
  };
  const [scannedTickets, setScannedTickets] = useState([]);
  const [gameTicketScanNo, setGameTicketScanNo] = useState("");
  const [bookTicketScanNo, setBookTicketScanNo] = useState("");
  const [ticketScanNo, setTicketScanNo] = useState();
  const handleGameTicketScanChange = (e) => {
    const value = e.target.value;

    // Check if the value contains only numbers and has a maximum of 20 digits
    if (/^\d{0,20}$/.test(value)) {
      setGameTicketScanNo(value);
    } else {
      // Optional: You can show an error message or alert the user
      console.log("Only 20 numeric characters are allowed.");
    }
  };
    const handleBookTicketScanChange = (e) => {
    const value = e.target.value;

    // Check if the value contains only numbers and has exactly 20 digits
    if (/^\d{0,20}$/.test(value)) {
      setBookTicketScanNo(value);
    } else {
      // Optional: You can show an error message or alert the user
      console.log("Only 20 numeric characters are allowed.");
    }
  };
    const handleTicketScanChange = (e) => {
    const value = e.target.value;

    // Check if the value contains only numbers and has exactly 20 digits
    if (/^\d{0,20}$/.test(value)) {
      setTicketScanNo(value);
    } else {
      // Optional: You can show an error message or alert the user
      console.log("Only 20 numeric characters are allowed.");
    }
  };

  const handleLotteryTicketScan = async () => {
    try {
      const payload = {
        activated_book_shift:shift_Id,
        game_no: gameTicketScanNo,
        book_no: bookTicketScanNo,
        today_ticket_no: ticketScanNo,
      };

      // Ensure Patch receives the endpoint as a proper string
      const response = await Post("updateGameAndInventory", payload).then(
        (response) => {
          toast.success("Ticket scanned successfully!");

          // Append new ticket data to scannedTickets

          // Clear the input fields
          setGameTicketScanNo("");
          setBookTicketScanNo("");
          setTicketScanNo("");
          setScanNumber("");
          setTicketNo("");
          setTicketScanNo("");
          setReloadLottoTable(!reloadLottoTable);
          setReloadOnScan(!reloadOnScan);
        }
      );
    } catch (error) {
      setReloadLottoTable(!reloadLottoTable);
      console.error("Failed to scan ticket:", error);
      toast.error("There was an error scanning the ticket.");
    }
  };
  /*===================================ticket scan=========================================*/

  const handleScanNumberChange = (e) => {
    let newScanNumber = e.target.value;

    // Check if the input contains only numeric characters and is up to 50 digits long
    if (/^\d{0,50}$/.test(newScanNumber)) {
      setScanNumber(newScanNumber);

      if (newScanNumber.length >= 14) {
        const _gameNo = getTicketWithGameAndBook(newScanNumber).gameNo;
        const _bookNo = getTicketWithGameAndBook(newScanNumber).bookNo;
        const _ticketNo = getTicketWithGameAndBook(newScanNumber).ticketNo;
        let todayTicketNo = getTodayTicketNo(scannedTickets, _gameNo);
        console.log(todayTicketNo)

        setLastTicketNo(todayTicketNo);

        if (newScanNumber.length === 15) {
          setReloadOnScan(!reloadOnScan);
        }

        // Set state with extracted values
        setGameNo(_gameNo);
        setBookNo(_bookNo);
        setTicketNo(_ticketNo);
        setTicketScanNo(_ticketNo);
        setGameReturnNo(_gameNo);
        setBookReturnNo(_bookNo);
        setGameTicketScanNo(_gameNo);
        setBookTicketScanNo(_bookNo);
      }
    } else {
      // Optional: Log an error or give feedback if the input is invalid
      console.log("Only numeric characters up to 50 digits are allowed.");
    }
  };


  const ScanedTicketColumns = [
    { header: "Game Price", field: "game_price" },
    { header: "Game Name", field: "game_name" },
    { header: "Game Number", field: "game_no" },
    { header: "Book Number", field: "book_no" },
    { header: "Activated on", field: "active_date" },
    { header: "Last Ticket No", field: "last_ticket_no" },
    { header: "Today Ticket No", field: "today_ticket_no" },

    { header: "Quantity Sold", field: "quantity_sold" },
    { header: "Total", field: "total" },
  ];

  const getTodayTicketNo = (dataArray, gameNumber) => {
    const game = dataArray.find((item) => item.game_no === gameNumber);
    return game ? game.today_ticket_no : null; // Returns null if game not found
  };
  const renderRegisterInputs = (registers, fieldName, labelPrefix) => {
    // Add more robust logging and error handling
    if (!registers || !Array.isArray(registers) || registers.length === 0) {
      console.warn(`No valid registers found for ${fieldName}`);
      return null;
    }

    return registers
      .sort((a, b) => a.register_no - b.register_no)
      .map((register, index) => {
        // More robust value extraction with additional fallbacks
        const registerValue =
          register[fieldName] !== undefined && register[fieldName] !== null
            ? register[fieldName].toString()
            : "";

        return (
          <div
            key={`${fieldName}_${register.register_no}`}
            className="data-report"
          >
            <label
              htmlFor={`${fieldName}_${register.register_no}`}
              className="label"
            >
              {`${labelPrefix} ${index + 1}`}
            </label>
            <input
              type="text"
              step="any"
              id={`${fieldName}_${register.register_no}`}
              name={`${fieldName}_${register.register_no}`}
              className="input"
              max="9999"
              value={registerValue}
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                  .slice(0, 12); 
              }}
              onChange={(e) => {
                // Update local state immediately
                const updatedRegisters = [...registers];
                const registerIndex = updatedRegisters.findIndex(
                  (r) => r.register_no === register.register_no
                );
                if (registerIndex !== -1) {
                  updatedRegisters[registerIndex] = {
                    ...updatedRegisters[registerIndex],
                    [fieldName]: e.target.value,
                  };

                  // You might want to update the parent component's state here
                  // For example, if you have a function to update shiftDetails
                  setShiftDetails((prevDetails) => ({
                    ...prevDetails,
                    register_net_sales: updatedRegisters,
                  }));
                }
              }}
              onBlur={(e) => {
                const finalValue = e.target.value;
                handleFieldChange(register.register_no, fieldName, finalValue);
              }}
            />
          </div>
        );
      });
  };
  const renderLotteryRegisterInputs = (registers, fieldName, labelPrefix) => {
    if (!registers || registers.length === 0) {
      console.warn(`No lottery registers found for ${fieldName}`);
      return null;
    }

    return registers
      .sort((a, b) => a.register_no - b.register_no)
      .map((register, index) => {
        // Use controlled input approach
        return (
          <div
            key={`${fieldName}_${register.register_no}`}
            className="data-report"
          >
            <label
              htmlFor={`${fieldName}_${register.register_no}`}
              className="label"
            >
              {`${labelPrefix} ${index + 1}`}
            </label>
            <input
              type="text"
              step="any"
              id={`${fieldName}_${register.register_no}`}
              name={`${fieldName}_${register.register_no}`}
              className="input"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                  .slice(0, 12); 
              }}
              value={register[fieldName] || ""} // Use value instead of defaultValue
              onChange={(e) => {
                // Create a copy of the registers and update the specific register
                const updatedRegisters = registers.map((reg) =>
                  reg.register_no === register.register_no
                    ? { ...reg, [fieldName]: e.target.value }
                    : reg
                );

                // Update the shiftDetails state to reflect the changes
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  lottery_register_reports: updatedRegisters,
                }));
              }}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  register.register_no,
                  fieldName,
                  e.target.value,
                  true
                )
              }
            />
          </div>
        );
      });
  };

  // Safely check if `purchase_expenses` exists before rendering the table rows
  const filteredExpenses = Array.isArray(shiftDetails.purchase_expenses)
    ? shiftDetails.purchase_expenses.filter((entry) => entry.type === "other")
    : [];
    const availableInvoices = filteredInvoices.filter(invoice =>
      !shiftDetails.purchase_expenses?.some(
        expense => expense.object_id === invoice.id && expense.type === 'purchase_expense'
      ) &&
      !invoiceFields.some(field => field.invoiceId === invoice.id)
    );const availablePayrollEntries = payrollData.filter(payroll =>
      !shiftDetails.purchase_expenses?.some(
        expense => expense.object_id === payroll.id && expense.type === 'other'
      )
    );

  return (
    <div className="business-header">
      <div className="header-row">
        <div className="business-title">
          <div className="dropdown">
            <select
              id="shift-select"
              value={selectedShift}
              className="dropdown-toggle shift"
              onChange={handleShiftChange}
              style={{
                padding: "10px",
                marginBottom: "20px",
                fontSize: "16px",
                height: "44px",
              }}
            >
              {shifts.length > 0 ? (
                shifts.map((shiftItem) => (
                  <option
                    key={shiftItem.shift.id}
                    value={shiftItem.shift.title}
                    className="shift-text"
                  >
                    {shiftItem.shift.title}
                  </option>
                ))
              ) : (
                <option value="">No active shifts available</option>
              )}
            </select>
          </div>
          <input
            className="form-control"
            type="date"
            name="reportDate"
            value={selectedDate}
            onChange={handleDateChange}
            style={{
              backgroundColor: "#f4f4f4",
              marginLeft: "10px",
              height: "44px",
            }}
          />
        </div>
        <div className="btn-position">
          <PrintReport
            reportData={printReport}
            selectedShift={selectedShift}
            today={today}
          />

          <button
            className="business-button button-height"
            onClick={handleEmailButtonClick}
          >
            <Email />
            Email Report
          </button>

          <button
            className="business-button del button-height"
            onClick={handleModalShift}
          >
            End Shift
          </button>
          <ReusableModal2
            show={showModal}
            handleClose={handleCloseModal}
            title="End Shift Confirmation"
            title2="Do you really want to end this shift?"
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleShiftEnd();
              }}
            >
              <div className="col-md-3 d-flex align-items-center">
                <label htmlFor="shift" className="form-label mb-0 me-2">
                  Shift:
                </label>
                <input
                  style={{ width: "220px" }}
                  type="text"
                  id="shift"
                  className="form-control"
                  value={selectedShift}
                  readOnly
                />
              </div>
              <div className="col-md-3 d-flex align-items-center">
                <label htmlFor="time" className="form-label mb-0 me-2">
                  Time:
                </label>
                <input
                  style={{ width: "220px" }}
                  type="text"
                  id="time"
                  className="form-control"
                  value={new Date().toLocaleString()}
                  readOnly
                />
              </div>

              <div className="d-flex justify-content-end mb-3">
                <button
                  type="button"
                  className="btn btn-contained me-2"
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn"
                  style={{
                    backgroundColor: "#E7785F",
                    width: "150px",
                    borderRadius: "50px",
                    color: "white",
                  }}
                >
                  End Shift
                </button>
              </div>
            </form>
          </ReusableModal2>
        </div>
      </div>

      <div className="row business-title-header">
        <div className="col-md-6">
          <p className="business-report-title">Business Report</p>
        </div>
        <div className="col-md-6">
          <p className="business-report-title">Purchase/Expense</p>
        </div>
        <div className="col-md-4"></div>
      </div>

      <div className="row business-report">
        <div className="col-md-6">
          {register_net_sales && register_net_sales.length > 0 ? (
            renderRegisterInputs(
              register_net_sales,
              "net_sales",
              "Net Sales Register"
            )
          ) : (
            <div>No register data available</div>
          )}

          {register_net_sales.length > 0 ? (
            <>
              {renderRegisterInputs(
                register_net_sales,
                "net_taxes",
                "Net Taxes"
              )}
            </>
          ) : (
            <div className="data-report">No register data available</div>
          )}

          <div className="data-report">
            <label htmlFor="total_net_sales" className="label">
              Total Net Sales
            </label>
            <input
              style={{ background: "#F4F4F470", border: "none" }}
              type="text"
              step="any"
              id="total_net_sales"
              name="total_net_sales"
              className="input"
              value={totalNetSales}
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                  .slice(0, 12); 
              }}
              readOnly
            />
          </div>
          <div className="data-report">
            <label htmlFor="total_net_taxes" className="label">
              Total Net Taxes
            </label>
            <input
              style={{ background: "#F4F4F470", border: "none" }}
              type="text"
              step="any"
              id="total_net_taxes"
              name="total_net_taxes"
              className="input"
              value={totalNetTaxes}
              readOnly
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                  .slice(0, 12); 
              }}
            />
          </div>

          <div
            className="hr"
            style={{
              width: "500px",
            }}
          >
            <hr />
          </div>

          <div className="data-report">
            <label htmlFor={`meal_tax`} className="label">
              Meal Tax
            </label>
            <input
              type="text"
              step="any"
              id={`meal_tax`}
              name={`meal_tax`}
              className="input-readonly"
              value={meal_tax === "N/A" ? "" : meal_tax}
              onChange={(e) => {
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    ["meal_tax"]: e.target.value,
                  },
                }));
              }}
              onBlur={
                (e) => handleGlobalField("meal_tax", e.target.value) // Directly call handleGlobalField
              }
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                  .slice(0, 12); 
              }}
            />
          </div>

          <div className="data-report">
            <label htmlFor="gross_sales" className="label">
              Gross Sales
            </label>
            <input
              type="text"
              step="any"
              id="gross_sales"
              name="gross_sales"
              className="input-readonly"
              value={grossSales}
              readOnly
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                  .slice(0, 12); 
              }}
            />
          </div>

          <div className="data-report">
            <label htmlFor={`refund`} className="label">
              Refund
            </label>
            <input
              type="text"
              step="any"
              id={`refund`}
              name={`refund`}
              className="input"
              value={refund === "N/A" ? "" : refund}
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                  .slice(0, 12); 
              }}
              onChange={(e) => {
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    ["refund"]: e.target.value,
                  },
                }));
              }}
              onBlur={(e) => handleGlobalField("refund", e.target.value)}
            />
          </div>
          <div className="data-report">
            <label htmlFor={`cheque`} className="label">
              Cheque
            </label>
            <input
              type="text"
              step="any"
              id={`cheque`}
              name={`cheque`}
              className="input"
              value={cheque === "N/A" ? "" : cheque}
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                  .slice(0, 12); 
              }}
              onChange={(e) => {
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    ["cheque"]: e.target.value,
                  },
                }));
              }}
              onBlur={(e) => handleGlobalField("cheque", e.target.value)}
            />
          </div>
          <div className="data-report">
            <label htmlFor={`credit_card`} className="label">
              Credit Card
            </label>
            <input
              type="text"
              step="any"
              id={`credit_card`}
              name={`credit_card`}
              className="input"
              value={credit_card === "N/A" ? "" : credit_card}
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                  .slice(0, 12); 
              }}
              onChange={(e) => {



                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    ['credit_card']: e.target.value,
                  },
                }));
              }}
              onBlur={(e) =>
                handleGlobalField("credit_card", e.target.value)
              }
            />
          </div>
          {register_net_sales.length > 0 ? (
            <>
              {renderRegisterInputs(
                register_net_sales,
                "register_amount",
                "Register Cash"
              )}
            </>
          ) : (
            <div className="data-report">No register data available</div>
          )}



          <div className="data-report">
            <label htmlFor={`total_register_cash`} className="label">
              Total Register Cash
            </label>
            <input
              type="text"
              step="any"
              id={`total_register_cash`}
              name={`total_register_cash`}
              className="input-readonly"
              value={totalRegisterCash}
              readOnly
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
            />
          </div>
          <div className="data-report">
            <label htmlFor={`cash_to_account`} className="label">
              Cash to Account
            </label>
            <input
              type="text"
              step="any"
              id={`cash_to_account`}
              name={`cash_to_account`}
              className="input-readonly"
              value={cashToAccount === "N/A" ? "" : cashToAccount}
              onChange={(e) => {
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    ["cash_to_account"]: e.target.value,
                  },
                }));
              }}
              onBlur={(e) =>
                handleGlobalField("cash_to_account", e.target.value)
              }
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              readOnly
            />
          </div>
          <div className="data-report">
            <label htmlFor={`over_short`} className="label">
              Over Short
            </label>
            <input
              type="text"
              step="any"
              id={`over_short`}
              name={`over_short`}
              className="input-readonly"
              value={overShort}
              onChange={(e) => handleGlobalField("over_short", e.target.value)}
              readOnly
               onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
            />
          </div>

          <div className="data-report">
            <label htmlFor={`credit_card`} className="label">
              Credit Card
            </label>
            <input
              type="text"
              step="any"
              id={`credit_card`}
              name={`credit_card`}
              className="input-readonly"
              value={credit_card === "N/A" ? "" : credit_card}
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              onChange={(e) => {
                setShiftDetails((prevDetails) => ({
                  ...prevDetails,
                  business_report: {
                    ...prevDetails.business_report,
                    ["credit_card"]: e.target.value,
                  },
                }));
              }}
              onBlur={(e) => handleGlobalField("credit_card", e.target.value)}
            />
          </div>
        </div>

        <div className="col-md-6">
          <div className="col-md-12">
            <div className="col-md-12">
              <div className="data-report">
                <label htmlFor="invoice" className="label">
                  Select Invoice
                </label>
                <select
                  className="dropdown-toggle shift"
                  style={{
                    padding: "10px",
                    marginBottom: "20px",
                    fontSize: "16px",
                    height: "44px",
                  }}
                  value={selectedInvoice}
                  onChange={handleInvoiceSelect}
                >
                  <option value="">Select Invoice</option>
                  <option value="addInvoice">+ Add Invoice</option>
                                  {availableInvoices.map(invoice => (
    <option key={invoice.id} value={invoice.id}>
      {invoice.invoice_number} - {invoice.vendor_department_name} - {invoice.invoice_no}
    </option>
  ))}
                </select>

                <Dialog open={open} maxWidth="lg" fullWidth>
                  <DialogContent style={{ backgroundColor: "transparent" }}>
                    <InvoiceForm onCancel={handleClose} />
                  </DialogContent>
                </Dialog>
              </div>
              <ToastContainer />



              {invoiceFields.map((field) => (
                <div
                  key={field.id}
                  className="data-report d-flex align-items-center mb-2"
                >
                  <div className="flex-grow-1 me-2">
                    <label className="label">Vendor Department</label>
                    <input
                      type="text"
                      className="input"
                      value={field.vendorDepartment}
                      readOnly
                    />
                  </div>
                  <div className="flex-grow-1 me-2">
                    <label className="label">Amount</label>
                    <input
                      type="text"
                      step="any"
                      className="input"
                      value={field.amount}
                      onInput={(e) => {
                        // Allow only numbers and a decimal point with two digits after it
                        e.target.value = e.target.value
                          .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                            .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                          .slice(0, 12); 
                      }}
                      onChange={(e) => {
                        // Create a temporary local update
                        const updatedFields = invoiceFields.map(
                          (invoiceField) =>
                            invoiceField.id === field.id
                              ? { ...invoiceField, amount: e.target.value }
                              : invoiceField
                        );
                        setInvoiceFields(updatedFields);
                      }}
                      onBlur={(e) =>
                        handleInvoiceFieldChange(
                          field.id,
                          "amount",
                          e.target.value
                        )
                      }
                      placeholder="Enter amount"
                    />
                  </div>
                  <div className="flex-grow-1 me-2">
                    <label className="label">Original Amount</label>
                    <input
                      type="text"
                      step="any"
                      className="input"
                      value={field.originalAmount}
                      readOnly
                      onInput={(e) => {
                        // Allow only numbers and a decimal point with two digits after it
                        e.target.value = e.target.value
                          .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                            .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                          .slice(0, 12); 
                      }}
                    />
                  </div>
                  <button
                    className="btn btn-danger mt-4"
                    onClick={() => removeInvoiceField(field.id)}
                  >
                    Remove
                  </button>
                </div>
              ))}

              {/* Total Invoice Amount */}
              {invoiceFields.length > 0 && (
                <div className="data-report">
                  <label className="label">Total Invoice Amount</label>
                  <input
                    type="text"
                    step="any"
                    className="input"
                    value={calculateTotalInvoiceAmount()}
                    readOnly
                    onInput={(e) => {
                      // Allow only numbers and a decimal point with two digits after it
                      e.target.value = e.target.value
                        .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                          .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                        .slice(0, 12); 
                    }}
                  />
                </div>
              )}

              <div className="data-report">
                <div className="col-md-12 d-flex justify-content-center">
                  <button
                    className="btn btn-contained m-2"
                    onClick={() => navigate("/purchase")}
                    style={{ color: "#4545DB" }} // Apply color inline
                  >
                    Purchase Invoice
                  </button>
                  <button
                    className="btn btn-contained m-2"
                    onClick={() => navigate("/expense")}
                    style={{ color: "#4545DB" }} // Apply color inline
                  >
                    Expense Invoices
                  </button>
                </div>
              </div>
            </div>
          </div>
          <table className="table table-hover align-middle mb-0">
            <thead className="table-light">
              <tr style={{ textAlign: "center" }}>
                <th
                  style={{
                    alignContent: "center",
                    border: "none",
                    borderTopLeftRadius: "30px", // Top-left rounded corner
                    borderBottomLeftRadius: "30px", // Bottom-left rounded corner
                    textAlign: "center", // Center text horizontally
                  }}
                >
                  Vendor Name
                </th>
                <th
                  style={{
                    alignContent: "center",
                    border: "none",
                    textAlign: "center", // Center text horizontally
                  }}
                >
                  Invoice No
                </th>
                <th
                  style={{
                    alignContent: "center",
                    border: "none",
                    textAlign: "center", // Center text horizontally
                  }}
                >
                  Amount
                </th>
                <th
                  className="text-center"
                  style={{
                    alignContent: "center",
                    border: "none",
                    borderTopRightRadius: "30px", // Top-right rounded corner
                    borderBottomRightRadius: "30px", // Bottom-right rounded corner
                    textAlign: "center", // Center text horizontally
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {/* Check if shiftDetails and shiftDetails.purchase_expenses are defined and ensure it's an array */}
              {shiftDetails &&
              Array.isArray(shiftDetails.purchase_expenses) &&
              shiftDetails.purchase_expenses.length === 0 ? (
                <tr>
                  <td colSpan="3" className="text-center">
                    No data available
                  </td>
                </tr>
              ) : // Check if shiftDetails.purchase_expenses is an array before filtering
              shiftDetails &&
                Array.isArray(shiftDetails.purchase_expenses) &&
                shiftDetails.purchase_expenses.length > 0 ? (
                shiftDetails.purchase_expenses.filter(
                  (entry) => entry.type === "purchase_expense"
                ).length === 0 ? (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No data available for purchase expenses
                    </td>
                  </tr>
                ) : (
                  shiftDetails.purchase_expenses
                    .filter((entry) => entry.type === "purchase_expense")
                    .map((expense) => (
                      <tr key={expense.id}>
                        <td className="text-center">
                          <span
                            className="fw-bold"
                            style={{ color: "#212529" }}
                          >
                            {expense.title_id || "N/A"}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-success fw-semibold">
                            {expense.invoice_no ? expense.invoice_no : "N/A"}
                          </span>
                        </td>
                        <td className="text-center">
                          <span className="text-success fw-semibold">
                            ${parseFloat(expense.amount).toFixed(2)}
                          </span>
                        </td>
                        <td className="text-center">
                          <div className="dropdown">
                            <button
                              className="btn btn-outline-secondary btn-sm"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="bi bi-three-dots-vertical"></i>
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <button
                                  className="dropdown-item text-danger"
                                  onClick={() =>
                                    handleDeleteInvoice(expense.id)
                                  }
                                >
                                  <i className="bi bi-trash me-2"></i>Delete
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    ))
                )
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No data available for purchase expenses
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="data-report">
            <label htmlFor="payroll" className="label">
              Select Payroll
            </label>
            <select
              id="payroll"
              className="input"
              value={selectedPayroll ? selectedPayroll.id : ""}
              onChange={handlePayrollSelect}
            >
              <option value="">Choose Payroll</option>
              {availablePayrollEntries.map(payroll => (
    <option key={payroll.id} value={payroll.id}>
      {payroll.payroll_employee_name} - ${payroll.total_amount}
    </option>
  ))}
            </select>
          </div>
          {selectedPayroll && (
            <div className="data-report d-flex align-items-center mb-2">
              <div className="flex-grow-1 me-2">
                <label className="label">Employee Name</label>
                <input
                  type="text"
                  className="input"
                  value={selectedPayroll.payroll_employee_name}
                  readOnly
                />
              </div>
              <div className="flex-grow-1 me-2">
                <label className="label">Total Amount</label>
                <input
                  type="text"
                  step="any"
                  className="input"
                  defaultValue={selectedPayroll.total_amount}
                  onBlur={(e) => handlePayrollAmountChange(e, selectedPayroll)}
                  onInput={(e) => {
                    // Allow only numbers and a decimal point with two digits after it
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                        .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                      .slice(0, 12); 
                  }}
                  placeholder="Enter amount"
                />
              </div>
              <div className="flex-grow-1 me-2">
                <label className="label">Pay Method</label>
                <input
                  type="text"
                  className="input"
                  value={selectedPayroll.pay_method}
                  readOnly
                />
              </div>
            </div>
          )}

          <table className="table table-hover align-middle mb-0">
            <thead className="table-light">
              <tr style={{ textAlign: "center" }}>
                <th
                  style={{
                    alignContent: "center",
                    border: "none",
                    borderTopLeftRadius: "30px", // Top-left rounded corner
                    borderBottomLeftRadius: "30px", // Bottom-left rounded corner
                    textAlign: "center", // Center text horizontally
                  }}
                >
                  Employee Name
                </th>
                <th
                  style={{
                    alignContent: "center",
                    border: "none",
                    textAlign: "center", // Center text horizontally
                  }}
                >
                  Description
                </th>
                <th
                  style={{
                    alignContent: "center",
                    border: "none",
                    textAlign: "center", // Center text horizontally
                  }}
                >
                  Amount
                </th>
                <th
                  className="text-center"
                  style={{
                    alignContent: "center",
                    border: "none",
                    borderTopRightRadius: "30px", // Top-right rounded corner
                    borderBottomRightRadius: "30px", // Bottom-right rounded corner
                    textAlign: "center", // Center text horizontally
                  }}
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody>
              {/* Check if filteredExpenses is empty */}
              {filteredExpenses.length === 0 ? (
                <tr>
                  <td colSpan="3" className="text-center">
                    No data available
                  </td>
                </tr>
              ) : (
                filteredExpenses.map((payrollEntry) => (
                  <tr key={payrollEntry.id}>
                    <td className="text-center">
                      <span className="fw-bold" style={{ color: "#212529" }}>
                        {payrollEntry.title_id || "N/A"}
                      </span>
                    </td>
                    <td className="text-center">
                      <span className="text-success fw-semibold">Salary</span>
                    </td>
                    <td className="text-center">
                      <span className="text-success fw-semibold">
                        ${parseFloat(payrollEntry.amount).toFixed(2)}
                      </span>
                    </td>
                    <td className="text-center">
                      <div className="dropdown">
                        <button
                          className="btn btn-outline-secondary btn-sm"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="bi bi-three-dots-vertical"></i>
                        </button>
                        <ul className="dropdown-menu">
                          <li>
                            <button
                              className="dropdown-item text-danger"
                              onClick={() =>
                                handleDeletePayrollEntry(payrollEntry)
                              }
                            >
                              <i className="bi bi-trash me-2"></i>Delete
                            </button>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className="mt-4">
        <p className="report-table-title">Daily Lottery Report</p>
      </div>
      <div className="row business-title-header">
        <div className="col-md-4">
          <p className="business-report-title">Today Invoice</p>
        </div>
        <div className="col-md-4">
          <p className="business-report-title">Yesterday Invoice</p>
        </div>
        <div className="col-md-4">
          <p className="business-report-title">Today Cash</p>
        </div>
      </div>
      <div className="row business-report">
        <div className="col-md-4">
          {/* Today Invoice Section */}
          {shiftDetails.lottery_register_reports?.length > 0 ? (
            <>
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "net_sales_sr50",
                "Online Net Sales"
              )}
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "online_cashing_sr50",
                "Online Cashing"
              )}
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "instant_cashing_sr34",
                "Instant Cashing"
              )}
            </>
          ) : (
            <div className="data-report">
              No lottery register data available
            </div>
          )}

          <div className="data-report">
            <label htmlFor="total_net_sales_sr50" className="label">
              Total Net Sales SR50
            </label>
            <input
              type="text"
              step="any"
              id="total_net_sales_sr50"
              name="total_net_sales_sr50"
              className="input-readonly"
              value={lottery_report?.total_net_sales_sr50 || ""}
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_net_sales_sr50",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="total_online_cashing_sr50" className="label">
              Total Online Cashing SR50
            </label>
            <input
              type="text"
              step="any"
              id="total_online_cashing_sr50"
              name="total_online_cashing_sr50"
              className="input-readonly"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              value={lottery_report?.total_online_cashing_sr50 || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_online_cashing_sr50",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="total_instant_cashing_today" className="label">
              Total Instant Cashing Today
            </label>
            <input
              type="text"
              step="any"
              id="total_instant_cashing_today"
              name="total_instant_cashing_today"
              className="input-readonly"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              value={lottery_report?.total_instant_cashing_today || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_instant_cashing_today",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>
          <div className="data-report">
            <label htmlFor="instant_sale_sr34" className="label">
              Instant Sale SR34
            </label>
            <input
              type="text"
              step="any"
              id="instant_sale_sr34"
              name="instant_sale_sr34"
              className="input"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              defaultValue={lottery_report?.instant_sale_sr34 || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "instant_sale_sr34",
                  e.target.value,
                  false
                )
              }
            />
          </div>
        </div>

        <div className="col-md-4">
          {/* Yesterday Invoice Section */}
          {shiftDetails.lottery_register_reports?.length > 0 ? (
            <>
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "net_sales_sr51",
                "Online Net Sales"
              )}
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "online_cashing_sr51",
                "Online Cashing"
              )}
              {renderLotteryRegisterInputs(
                shiftDetails.lottery_register_reports,
                "instant_cashing_sr35",
                "Instant Cashing "
              )}
            </>
          ) : (
            <div className="data-report">
              No lottery register data available
            </div>
          )}

          <div className="data-report">
            <label htmlFor="total_net_sales_sr51" className="label">
              Total Net Sales SR51
            </label>
            <input
              type="text"
              step="any"
              id="total_net_sales_sr51"
              name="total_net_sales_sr51"
              className="input-readonly"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              value={lottery_report?.total_net_sales_sr51 || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_net_sales_sr51",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="total_online_cashing_sr51" className="label">
              Total Online Cashing SR51
            </label>
            <input
              type="text"
              step="any"
              id="total_online_cashing_sr51"
              name="total_online_cashing_sr51"
              className="input-readonly"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              value={lottery_report?.total_online_cashing_sr51 || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_online_cashing_sr51",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>

          <div className="data-report">
            <label htmlFor="total_instant_cashing_yesterday" className="label">
              Total Instant Cashing Yesterday
            </label>
            <input
              type="text"
              step="any"
              id="total_instant_cashing_yesterday"
              name="total_instant_cashing_yesterday"
              className="input-readonly"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              value={lottery_report?.total_instant_cashing_yesterday || ""}
              onChange={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_instant_cashing_yesterday",
                  e.target.value,
                  false
                )
              }
              readOnly
            />
          </div>
        </div>

        <div className="col-md-4">
          {" "}
          {/* Today Cash Section */}
          <div className="data-report">
            <label htmlFor="total_online_balance" className="label">
              Total Online Balance
            </label>
            <input
              type="text"
              step="any"
              id="total_online_balance"
              name="total_online_balance"
              className="input-readonly"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              defaultValue={lottery_report?.total_online_balance || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "total_online_balance",
                  e.target.value,
                  false
                )
              }
            />
          </div>
          <div className="data-report">
            <label htmlFor="credit_sales" className="label">
              Credit Sales
            </label>
            <input
              type="text"
              step="any"
              id="credit_sales"
              name="credit_sales"
              className="input"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              defaultValue={lottery_report?.credit_sales || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "credit_sales",
                  e.target.value,
                  false
                )
              } // onBlur to update value
            />
          </div>
          <div className="data-report">
            <label htmlFor="debit_sales" className="label">
              Debit Sales
            </label>
            <input
              type="text"
              step="any"
              id="debit_sales"
              name="debit_sales"
              className="input"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              defaultValue={lottery_report?.debit_sales || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "debit_sales",
                  e.target.value,
                  false
                )
              } // onBlur to update value
            />
          </div>
          <div className="data-report">
            <label htmlFor="register_cash" className="label">
              Register Cash
            </label>
            <input
              type="text"
              step="any"
              id="register_cash"
              name="register_cash"
              className="input-readonly"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              defaultValue={lottery_report?.register_cash || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "register_cash",
                  e.target.value,
                  false
                )
              } // onBlur to update value
            />
          </div>
          <div className="data-report">
            <label htmlFor="over_short" className="label">
              Over Short
            </label>
            <input
              type="text"
              step="any"
              id="over_short"
              name="over_short"
              className="input-readonly"
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              value={lottery_report?.over_short || ""}
              onBlur={(e) =>
                handleLotteryFieldChange(
                  null,
                  "over_short",
                  e.target.value,
                  false
                )
              } // onBlur to update value
            />
          </div>
        </div>
      </div>


      <div className="mt-4">
        <p className="report-table-title">Lottery Activated Books</p>
      </div>
      <div className="row business-title-header">
        <div className="col-md-4">
          <p className="business-report-title">Scan Code and Activate</p>
        </div>
        <div className="col-md-4">
          <p className="business-report-title">Activate Manually</p>
        </div>
        <div className="col-md-4">
          <p className="business-report-title">Return Book</p>
        </div>
      </div>
      <div className="row business-report">
        <div className="col-md-4">
          <div className="data-report">
            <div className="input-lottery-container">
              <input
                type="text"
                id="gross-sales"
                className="input-lottery"
                placeholder="Scan Number"
                value={scanNumber}
                onChange={handleScanNumberChange}
              />
              <div className="svg-icon">
                <LottoIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="data-report">
            <input
              type="text"
              id="gross-sales"
              className="input-game field"
              placeholder="Game Number"
              value={gameNo}
              onChange={handleGameNoChange}
            />
          </div>
          <div className="data-report">
            <input
              type="text"
              id="gross-sales"
              className="input-game field"
              placeholder="Book Number"
              value={bookNo}
              onChange={handleBookNoChange}
            />
            <button className="btn-activate" onClick={handleActivate}>
              Activate
            </button>
          </div>
        </div>
        <div className="col-md-4">
          <div className="data-report">
            <input
              type="text"
              id="gross-sales"
              className="input-game field"
              placeholder="Game Number"
              value={gameReturnNo}
              onChange={handleGameReturnNoChange}
            />
          </div>
          <div className="data-report">
            <input
              type="text"
              className="input-game field"
              placeholder="Book Number"
              value={bookReturnNo}
              onChange={handleBookNoReturnChange}
            />
          </div>
          <div className="data-report">
            <input
              type="text"
              className="input-game field"
              placeholder="Ticket Number"
              value={ticketNo}
              onChange={handleTicketNoChange}
            />
            <button className="btn-activate" onClick={handleReturn}>
              Return Now
            </button>
          </div>
        </div>
      </div>
      <div className="row business-title-header">
      <div
        className={`col-md-2 ${
          activeTab === "activated" ? "active-tab" : "disabled-tab"
        }`}
        onClick={() => handleTabClick("activated")}
      >
        <p
          className={`business-report-title ${
            activeTab === "activated" ? "active" : ""
          }`}
        >
          Activated Books
        </p>
      </div>
      <div
        className={`col-md-2 ${
          activeTab === "returned" ? "active-tab" : "disabled-tab"
        }`}
        onClick={() => handleTabClick("returned")}
      >
        <p
          className={`business-report-title ${
            activeTab === "returned" ? "active" : ""
          }`}
        >
          Returned Books
        </p>
      </div>
    </div>

      <div className="">
      <LotteryReportTable
        lottoData={activeTab === "activated" ? activatedBooks : returnedBooks}
        columns={activeTab === "activated" ? activatedColumns : returnedColumns}
        showFooter={true}
        reloadLottoTable={reloadLottoTable}
      />
      </div>

      <div className="mt-4">
        <p className="report-table-title">Lottery Ticket Scan</p>
      </div>
      <div className="row business-title-header">
        <div className="col-md-4">
          <p className="business-report-title">Scan Code Here</p>
        </div>
        <div className="col-md-4">
          <p className="business-report-title">Add Ticket Manually</p>
        </div>
      </div>



      <div className="row business-report">
        <div className="col-md-4">
          <div className="data-report">
            <div className="input-lottery-container">
              <input
                type="text"
                id="gross-sales"
                className="input-lottery"
                placeholder="Scan Number"
                value={scanNumber}
                onChange={handleScanNumberChange}
              />
              <div className="svg-icon">
                <LottoIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="data-report">
            <input
              type="text"
              id="gross-sales"
              className="input-game field"
              placeholder="Game Number"
              value={gameTicketScanNo}
              onChange={handleGameTicketScanChange}
            />
          </div>
          <div className="data-report">
            <input
              type="text"
              className="input-game field"
              placeholder="Book Number"
              value={bookTicketScanNo}
              onChange={handleBookTicketScanChange}
            />
          </div>
          <div className="data-report">
            <input
              type="text"
              className="input-game field"
              placeholder="Ticket Number"
              value={ticketScanNo}
              onChange={handleTicketScanChange}
            />
            <button className="btn-scanning" onClick={handleLotteryTicketScan}>
              Finish Scanning
            </button>
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <p className="count-ticket">
            Count : ({lastTicketNo}) Last Ticket Scanned :
          </p>
        </div>
      </div>
      <div className="mt-4"></div>
      <div className="row business-title-header">
        <div
          className={`col-md-2 ${
            activeTab === "activated" ? "active-tab" : "disabled-tab"
          }`}
          onClick={() => handleTabClick("activated")}
        >
          <p
            className={`business-report-title ${
              activeTab === "activated" ? "active" : ""
            }`}
          >
            Scanned Tickes
          </p>
        </div>
      </div>
      <div className="table-container">
      <LotteryReportTable
  lottoData={activeTab === "activated" ? scannedTickets : []}
  columns={activeTab === "activated" ? ScanedTicketColumns : []}
  showFooter={true}
  reloadLottoTable={reloadLottoTable}
/>

      </div>
      <ReusableModal2
        show={showEmailModal}
        handleClose={() => setShowEmailModal(false)}
        title="Email Daily Report"
      >
        <form onSubmit={handleSendEmail}>
          {/* Updated message */}
          <div className="mb-3">
            <p>The generated report will be sent to you and the store owner.</p>
          </div>

          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-contained me-2"
              onClick={() => setShowEmailModal(false)}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Send Report
            </button>
          </div>
        </form>
      </ReusableModal2>
    </div>
  );
}

export default BusinessReport;
