/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/css/App.css";
import logo from "../../assets/img/EZHISAB.png";
import AuthContext from "../../utils/secure-route/AuthContext";
import { useLocation, Link } from "react-router-dom";
import useApi from "../../utils/api-manager/Helper/useApi";
import shopeKeeper from "../../assets/img/shopkeeper.png";
import SubscriptionModal from "../Pages/AppPAges/ManageSettings/SubscriptionSelectModal";

const Header = () => {
  const { logOutUser, user } = useContext(AuthContext);
  const [avatarDropdownOpen, setAvatarDropdownOpen] = useState(false);
  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const [generalDropdownOpen, setGeneralDropdownOpen] = useState(false);
  const [expenseDropdownOpen, setExpenseDropdownOpen] = useState(false);
  const [lotteryDropdownOpen, setLotteryDropdownOpen] = useState(false);
  const [gasDropdownOpen, setGasDropdownOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const [remainingDays, setRemainingDays] = useState(0);
  const navigate = useNavigate();
  const [userRole, setUserRole] = useState(null);
  const location = useLocation();
  const avatarRef = useRef(null);
  const notificationRef = useRef(null);
  const generalRef = useRef(null);
  const expenseRef = useRef(null);
  const settingsRef = useRef(null);
  const lotteryRef = useRef(null);
  const gasRef = useRef(null);
  const [selectedNav, setSelectedNav] = useState("/");
  const { Get, getAPI, Post } = useApi();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [currentStoreId, setCurrentStoreId] = useState(null);


  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  useEffect(() => {
    setSelectedNav(location.pathname);
    const handlecheckUser = async () => {
      try {
        const checkuser = await Get(`checkUserType`);
        setUserRole(checkuser.role_name);
        // Show modal if user is "store owner" and is logging in for the first time
        if (checkuser.role_name === "store owner" && checkuser.is_first_time) {
          setShowSubscriptionModal(true); // Trigger modal to show
        } else {
          setShowSubscriptionModal(false);
        }
      } catch (error) {
        console.error(error);
      }
    };

    handlecheckUser();
  }, [location.pathname]);
  const handleLogout = () => {
    logOutUser();
  };

  const handleStoreManageClick = async () => {
    setDropdownVisible(!isDropdownVisible);

    if (!isDropdownVisible) {
      setLoading(true);
      try {
        const response = await Get("getAllStoresOfUser");
        const { first_store, sub_stores } = response;
        const allStores = [first_store, ...(sub_stores || [])];
        setStores(allStores);
      } catch (error) {
        console.error("Error fetching stores:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleStoreClick = async (storeId) => {
    try {
      const response = await Post("selectStore", { store_id: storeId });
      setCurrentStoreId(response.data.store_id)
      navigate("/");
      setDropdownVisible(false);
      setAvatarDropdownOpen(false);
    } catch (error) {
      console.error("Error selecting store:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchTrialInfo();
  }, []);

  const fetchTrialInfo = async () => {
    try {
      const response = await Get("remainingTime");
      const { remaining_days } = response;
      setRemainingDays(remaining_days);
    } catch (error) {
      console.error("Error fetching trial information:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePlanSelection = (planType) => {
    setShowSubscriptionModal(false);
    if (planType === "free") {
      navigate("/login");
    }
    // Premium plan is handled within the modal component
  };

  const getColorClass = () => {
    if (remainingDays > 20) {
      return "bg-success"; // Green background for more than 20 days
    } else if (remainingDays > 10) {
      return "bg-warning"; // Yellow background for more than 10 days
    } else {
      return "bg-danger"; // Red background for 10 days or less
    }
  };

  const dropdownRefs = [
    avatarRef,
    notificationRef,
    generalRef,
    expenseRef,
    settingsRef,
    lotteryRef,
    gasRef,
  ];
  const handleClickOutside = (event) => {
    const isClickInsideDropdown = dropdownRefs.some(
      (ref) => ref.current && ref.current.contains(event.target)
    );

    if (!isClickInsideDropdown) {
      closeDropdowns();
    }
  };
  const closeDropdowns = () => {
    setAvatarDropdownOpen(false);
    setSettingsDropdownOpen(false);
    setGeneralDropdownOpen(false);
    setExpenseDropdownOpen(false);
    setLotteryDropdownOpen(false);
    setGasDropdownOpen(false);
    setDropdownVisible(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <header className="header-container">
     {showSubscriptionModal && (
        <SubscriptionModal
          show={showSubscriptionModal}
          handleClose={() => setShowSubscriptionModal(false)}
          onPlanSelect={handlePlanSelection}
          storeId={currentStoreId || user.store}
        />
      )}
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
  

          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMobileMenu}
          >
            <i className="fas fa-bars"></i>
          </button>
          <div
            className={`collapse navbar-collapse ${
              isMobileMenuOpen ? "show" : ""
            }`}
            id="navbarNav"
          >
            {" "}
            <ul className="navbar-nav me-auto">
              {userRole === "store owner" && (
                <li
                  className={`nav-item ${
                    selectedNav === "/owner-dashboard" ? "active" : ""
                  }`}
                >
                  <Link
                    className="nav-link"
                    to="/owner-dashboard"
                    onClick={() => {
                      setSelectedNav("/owner-dashboard");
                      closeDropdowns();
                    }}
                  >
                    Owner Dashboard
                  </Link>
                </li>
              )}

              <li className={`nav-item ${selectedNav === "/" ? "active" : ""}`}>
                <Link
                  className="nav-link"
                  to="/"
                  onClick={() => {
                    setSelectedNav("/");
                    closeDropdowns();
                  }}
                >
                  Dashboard
                </Link>
              </li>
              <li
                className={`nav-item ${selectedNav === "#" ? "active" : ""}`}
                ref={expenseRef}
                onClick={() => {
                  closeDropdowns();
                  setExpenseDropdownOpen(!expenseDropdownOpen);
                }}
              >
                <a
                  className="nav-link"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  Expense
                </a>
                {expenseDropdownOpen && (
                  <ul className="nav-menu-drop">
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/expense"
                        onClick={closeDropdowns}
                      >
                        Expense
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/purchase"
                        onClick={closeDropdowns}
                      >
                        Purchase
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`nav-item ${
                  selectedNav === "/payroll" ? "active" : ""
                }`}
              >
                <Link
                  className="nav-link"
                  to="/payroll"
                  onClick={() => {
                    setSelectedNav("/payroll");
                    closeDropdowns();
                  }}
                >
                  Payroll
                </Link>
              </li>

              <li
                className={`nav-item ${
                  selectedNav === "/reportDateSelection" ? "active" : ""
                }`}
              >
                <Link
                  className="nav-link"
                  to="/reportDateSelection"
                  onClick={() => {
                    setSelectedNav("/reportDateSelection");
                    closeDropdowns();
                  }}
                >
                  Reports
                </Link>
              </li>
              {(userRole === "store owner" || userRole === "store manager") && (
                <li
                  className={`nav-item ${selectedNav === "#" ? "active" : ""}`}
                  ref={generalRef}
                  onClick={() => {
                    closeDropdowns();
                    setGeneralDropdownOpen(!generalDropdownOpen);
                  }}
                >
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    General
                  </a>
                  {generalDropdownOpen && (
                    <ul className="nav-menu-drop">
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/ATM"
                          onClick={closeDropdowns}
                        >
                          ATM
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/Bank"
                          onClick={closeDropdowns}
                        >
                          Bank
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/OtherIncome"
                          onClick={closeDropdowns}
                        >
                          Other Income
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/SalesTax"
                          onClick={closeDropdowns}
                        >
                          Sales Tax
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/bankLedger"
                          onClick={closeDropdowns}
                        >
                          Bank Ledger
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/cashLedger"
                          onClick={closeDropdowns}
                        >
                          Cash Ledger
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/OwnerDist"
                          onClick={closeDropdowns}
                        >
                          Owner Distribution
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              )}
              <li
                className={`nav-item ${selectedNav === "#" ? "active" : ""}`}
                ref={lotteryRef}
                onClick={() => {
                  closeDropdowns();
                  setLotteryDropdownOpen(!lotteryDropdownOpen); // Toggle lottery dropdown
                }}
              >
                <a
                  className="nav-link"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  Lottery
                </a>
                {lotteryDropdownOpen && (
                  <ul className="nav-menu-drop">
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/lottery-inventory"
                        onClick={closeDropdowns}
                      >
                        Lottery Inventory
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/weekly-invoice-settlement"
                        onClick={closeDropdowns}
                      >
                        Weekly Invoice Settlement
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/book-movement"
                        onClick={closeDropdowns}
                      >
                        Book Movement
                      </Link>
                    </li>

                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/manage-games"
                        onClick={closeDropdowns}
                      >
                        Manage Games
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li
                className={`nav-item ${selectedNav === "#" ? "active" : ""}`}
                ref={gasRef}
                onClick={() => {
                  closeDropdowns();
                  setGasDropdownOpen(!gasDropdownOpen); // Toggle lottery dropdown
                }}
              >
                <a
                  className="nav-link"
                  href="#"
                  onClick={(e) => e.preventDefault()}
                >
                  Gas
                </a>
                {gasDropdownOpen && (
                  <ul className="nav-menu-drop">
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/gas-invoice"
                        onClick={closeDropdowns}
                      >
                        Gas Invoice
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/house-charges"
                        onClick={closeDropdowns}
                      >
                        House Charges
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/gas-tax-settings"
                        onClick={closeDropdowns}
                      >
                        Gas Tax Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="/gas-settings"
                        onClick={closeDropdowns}
                      >
                        Gas Settings
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              {userRole === "store owner" && (
                <li
                  className={`nav-item ${selectedNav === "#" ? "active" : ""}`}
                  ref={settingsRef}
                  onClick={() => {
                    closeDropdowns();
                    setSettingsDropdownOpen(!settingsDropdownOpen);
                  }}
                >
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                  >
                    Settings
                  </a>
                  {settingsDropdownOpen && (
                    <ul className="nav-menu-drop">
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/store-information"
                          onClick={closeDropdowns}
                        >
                          Store Information
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/manage-users"
                          onClick={closeDropdowns}
                        >
                          Manage Users
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/manage-departments"
                          onClick={closeDropdowns}
                        >
                          Manage Departments
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/manage-expense-type"
                          onClick={closeDropdowns}
                        >
                          Manage Expense Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/other-income-type"
                          onClick={closeDropdowns}
                        >
                          Other Income Type
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/manage-vendor"
                          onClick={closeDropdowns}
                        >
                          Manage Vendor
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-menu-drop-item"
                          to="/manage-settings"
                          onClick={closeDropdowns}
                        >
                          Manage Settings
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              )}
            </ul>
            <div className="d-flex align-items-center">
              <div className={`trail me-3 ${getColorClass()}`}>
                <p>Remaining days: {remainingDays}</p>
              </div>

              <div className="avatar-container" ref={avatarRef}>
                <img
                  src={shopeKeeper}
                  className="avatar"
                  alt="User Avatar"
                  onClick={() => {
                    closeDropdowns();

                    setAvatarDropdownOpen(!avatarDropdownOpen);
                  }}
                />
                {avatarDropdownOpen && (
                  <ul className="nav-menu-drop">
                    <li>
                      <Link
                        className="nav-menu-drop-item"
                        to="#"
                        onClick={closeDropdowns}
                      >
                        Profile
                      </Link>
                    </li>
                    {(userRole === "store owner" ||
                      userRole === "store manager") && (
                      <li>
                        <a
                          className="nav-menu-drop-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleStoreManageClick();
                          }}
                        >
                          Switch Store
                        </a>
                      </li>
                    )}
                    {isDropdownVisible && (
                      <div className="dropdown-container">
                        <ul className="dropdown-list">
                          {loading ? (
                            <li>Loading...</li>
                          ) : (
                            stores.map((store) => (
                              <li key={store.id} className="dropdown-item">
                                <a
                                  href="#"
                                  className="dropdown-link"
                                  onClick={(e) => {
                                    handleStoreClick(store.id);
                                    window.location.reload();
                                  }}
                                >
                                  {store.name}
                                </a>
                              </li>
                            ))
                          )}
                        </ul>
                      </div>
                    )}
                    <li>
                      <a
                        className="nav-menu-drop-item"
                        href="#"
                        onClick={() => {
                          handleLogout();
                          closeDropdowns();
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Header;
