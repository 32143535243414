/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Report.css";
import BusinessReport from "./BusinessReport";
import GasReport from "./GasReport";
import CashDrop from "./CashDrop";
import useApi from "../../../../utils/api-manager/Helper/useApi";

export default function Reports() {
  const [activeTab, setActiveTab] = useState('business report');
  const [isShiftActiveData, setIsShiftActiveData] = useState(null); // New state to hold the API response
  const { Get } = useApi();
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    fetchIsActive();
  }, []);

  const tabs = [
    { name: 'Business Report', content: <BusinessReport isShiftActiveData={isShiftActiveData} /> },
    { name: 'Gas Report', content: <GasReport /> },  
    { name: 'Cash Drop', content: <CashDrop /> },
    // { name: 'Lotto Vending', content: <LottoVending /> },
  ];

  const handleTabClick = (tab) => {
    if (!tab.disabled) {
      setActiveTab(tab.name.toLowerCase());
    }
  };

  const fetchIsActive = async () => {
    try {
      const response = await Get('isShiftActive');
      if (response.status === 200) {
        if (response.data.message === "You already have an active shift" && response.data.shift === "1_shift") {
          // Navigate to report date selection if shift is active
          navigate('/reportDateSelection');
        }
        setIsShiftActiveData(response.data); // Store the response data in state
      }
    } catch (error) {
      console.error("Error fetching shift status:", error);
      // Optionally handle errors (e.g., show a notification)
    }
  };

  return (
    <div className="report-container">
    
      <div className="tab-view">
        <ul className="nav report-tab">
          {tabs.map((tab) => (
            <li className="nav-item" key={tab.name}>
              <a
                className={`nav-link ${activeTab === tab.name.toLowerCase() ? 'active' : ''} ${tab.disabled ? 'disabled' : ''}`}
                href="#"
                onClick={() => handleTabClick(tab)}
                aria-disabled={tab.disabled}
              >
                {tab.name}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content mt-4">
          {tabs.map((tab) =>
            activeTab === tab.name.toLowerCase() && !tab.disabled ? (
              <div key={tab.name} className="tab-pane active">
                {tab.content}
              </div>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
}
