/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import DataTable from "./DataTable";
import useApi from "../../../../utils/api-manager/Helper/useApi";

import CheckoutForm from "./CheckoutForm";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionPopup from "./SubscriptionPopup";
import { ToastContainer, toast } from "react-toastify";


const stripePromise = loadStripe(
  "pk_test_51Q3jDRC4aAVHkmzjl5CIJRu4U8foi32rigkEJ6gFhEzTDrZvfwpZEwrpYxuugyXRLzAia2ZKoMxu58H6hVDuuko900kINa7O8c"
);

// import AuthContext from "../../../../utils/secure-route/AuthContext";
function StoreInformation() {
  const [showModal, setShowModal] = useState(false);
  const [plans, setPlans] = useState([]);

  const [currentPlan, setCurrentPlan] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [user, setUser] = useState(null);
  const [subscriptionEntryData, setSubscriptionEntryData] = useState([]);
  // const { user } = useContext(AuthContext);

  const { Get, getAPI, Post } = useApi();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        await Get("user").then((response) => {
          if (response && response.results && response.results.length > 0) {
            // If there are multiple users, you might need to handle that appropriately
            setUser(response.results[0]); // Adjust index as necessary
          }
        });
        await Get("subscriptionEntryData").then((resp) => {
          setSubscriptionEntryData(resp);
        });
      } catch (err) {
        console.error(err);
      }
    };

    getSubscriptionData();
    fetchUserData();
  }, [showModal]);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      const response = await Get("subscriptionsData");
      console.log(response);
      setPlans(response);
    } catch (error) {
      console.error("Error fetching subscription data:", error);
    }
  };

  const [modelData, setModelData] = useState({});

  const handleFreeSubscription = async (planId) => {
    try {
      // Call the paymentintent API directly with the subscription ID
      await Post("paymentIntent", {
        subscription_id: planId,
      });

      // Show success message
      toast.success("Free plan activated successfully!", {
        position: "top-right",
        autoClose: 3000,
      });

      // Refresh subscription data
      await getSubscriptionData();
    } catch (error) {
      console.error("Error activating free plan:", error);
      toast.error("You are not eligable for free plan !!");
    }
  };

  const handleShow = async (planid, amount) => {
    try {
      if (parseFloat(amount) === 0.0) {
        await handleFreeSubscription(planid);
      } else {
        let api = `${getAPI("subscriptionsData")}${planid}`;
        console.log(`API URL: ${api}`);
        await Get(api)
          .then((resp) => {
            // console.log(resp);
            setModelData(resp);
          })
          .catch((err) => {
            console.error("API request failed:", err);
          });
        setShowModal(true);
      }
    } catch (err) {}
  };

  const invoices = [
    { header: "Purchase Date", field: "start_date" },
    { header: "Title", field: "title" },
    { header: "Months", field: "no_of_months" },
    { header: "Amount Paid", field: "total_price" },
  ];
  const handleEdit = (expenseType) => {
    setShowModal(true);
  };
  const handleClose = () => {
    setShowModal(false);
  };
  const getButtonStyle = (plan) => {
    return currentPlan === plan
      ? { backgroundColor: "#FFFFFF", color: "#4545DB" }
      : { backgroundColor: "#FFFFFF", color: "#4545DB" };
  };

  const handlePlanChange = (planTitle) => {
    setCurrentPlan(planTitle);
  };
  const [activePlan, setActivePlan] = useState();

  const getSubscriptionData = async () => {
    await Get("getSubscriptionsByStore")
      .then((resp) => {
        setActivePlan(
          resp
            .filter((item) => item.is_active)

            .map((item) => item.title)
        );

        setSubscriptions(resp);
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <>
      <div className="store-container">
        <SubscriptionPopup
          show={showModal}
          handleClose={handleClose}
          width="50%"
          height="auto"
        >
          <Elements stripe={stripePromise}>
            <CheckoutForm
              //  amount={300}
              modelData={modelData}
              handleClose={handleClose}
            />
          </Elements>
        </SubscriptionPopup>
        <p className="setting-title">Store Information</p>
        <div className="input-container">
          <div className="input-row">
            <input
              type="text"
              style={{
                color: "#002300",
                fontSize: "18px",
                fontFamily: "Manrope",
              }}
              value={user ? user.selected_store_name : ""}
              placeholder="Store Name"
              className="input-field"
              required
              readOnly
            />
            <input
              type="email"
              style={{
                color: "#002300",
                fontSize: "18px",
                fontFamily: "Manrope",
              }}
              value={user ? user.email : ""}
              placeholder="Email"
              className="input-field"
              readOnly
            />
          </div>
        </div>
      </div>
      <div className="setting-container">
        {/*  monthly and yearly button */}
        <div
          style={{
            display: "flex",
            color: "#4545db",
            // background:'#ffffff',
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {/* <div
            style={{
              display: 'flex',
              backgroundColor: '#ffffff',
              border: '1px solid #6666ff',
              borderRadius: '50px',

              width: '200px',
              height: '40px',
              position: 'relative',
            }}
          >

            <button
              style={{
                flex: 1,

                border: 'none',
                background: view === 'monthly' ? '#6666ff' : 'none',
                color: view === 'monthly' ? '#fff' : '#6666ff',
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                borderRadius: '50px',
                transition: 'all 0.3s ease',
              }}
              onClick={() => setView('monthly')}
            >
              Monthly
            </button>


            <button
              style={{
                flex: 1,
                border: 'none',
                background: view === 'yearly' ? '#6666ff' : 'none',
                color: view === 'yearly' ? '#fff' : '#6666ff',
                fontSize: '14px',
                fontWeight: 'bold',
                cursor: 'pointer',
                borderRadius: '50px',
                transition: 'all 0.3s ease',
              }}
              onClick={() => setView('yearly')}
            >
              Yearly
            </button>
          </div> */}
        </div>

        {/* plan */}

        <div
          className="card-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            justifyContent: "center",
            margin: "10px",
          }}
        >
          {subscriptions.map((plan, index) => (
            <div
              key={index}
              className={`card-store ${plan.title.toLowerCase()} ${
                currentPlan === plan.title ? "selected" : ""
              }`}
              style={{
                border:
                  currentPlan === plan.title
                    ? "2px solid #6666ff"
                    : "1px solid #e5e5e5",
                borderRadius: "10px",
                padding: "20px",
                textAlign: "start",
                flex: "1 1 calc(30% - 20px)",
                maxWidth: "25%",
                minWidth: "300px",
                backgroundColor: "#f4f6ff",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "500px",
                overflow: "hidden",
              }}
              onClick={() => handlePlanChange(plan.title)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {plan.title}
                </p>
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#333",
                    position: "relative",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: -8,
                      right: -10,
                      fontSize: "16px",
                      color: "#333",
                    }}
                  >
                    $
                  </span>
                  {parseFloat(plan.amount).toFixed(2)}
                </p>
              </div>
              <div
                className="features"
                style={{ overflowY: "auto", padding: "10px 0", flex: "1" }}
              >
                <p style={{ fontWeight: "bold", fontSize: "16px" }}>Features</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    paddingLeft: "20px",
                    color: "#555",
                  }}
                >
                  {plan.inc_features_titles.map((features, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div
                        style={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#4caf50",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        ✓
                      </div>
                      <span>{features}</span>
                    </div>
                  ))}
                </div>
              </div>
              {!(plan.default && !plan.is_active) && ( // Button condition
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "auto",
                  }}
                >
                  <button
                    style={{
                      ...getButtonStyle(plan.is_active),
                      width: "100%",
                      padding: "10px 30px",
                      maxWidth: "300px",
                      textAlign: "center",
                      borderRadius: "20px",
                      border: "1px solid #6666ff",
                      fontWeight: "800",
                      backgroundColor: plan.is_active
                        ? "transparent"
                        : "#6666ff",
                      color: plan.is_active ? "#000" : "#fff",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleShow(plan.id, plan.amount);
                    }}
                    disabled={plan.is_active}
                  >
                    {plan.is_active ? "Active Plan" : "Get Started"}
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>

        <p style={{ textAlign: "center" }}>
          You have essential access to your ledger for basic transaction
          tracking and management.
          <br />
          Upgrade to unlock advanced tools, deeper insights, and enhanced
          features for better financial control.
        </p>
      </div>

      {/* <p className="card-store-description">{plan.details}</p>
                  <p className="card-store-sub-amount">
                    <span className="dollar-sign">$</span>
                    {plan.amount}
                  </p>
                  {plan.is_active === false ? (
                    <button
                      className="card-store-button"
                      style={getButtonStyle(plan.title)}
                      onClick={() => handleShow(plan.id)}
                    >
                      Change Plan
                    </button>
                  ) :


                   */}
      {/* (
                    <button
                      className="card-store-button"

                     disabled

                    >
                      Active Plan
                    </button>
                  )
                  }
                </div>
              ))
            : ""}
        </div>
      </div>
  */}
      {/* <div className="due-days">
        <div className="header-row">
          <div className="setting-title">Saved Cards</div>
          <button className="bank-add-button"> + Add Card</button>
        </div>
        <div className="saved-card">
          <div className="card-head">
            <p className="credit-card-label">Credit Card</p>
            <p className="bank-name-card">BOB Bank</p>
          </div>
          <div className="card-number">
            <p>1234 5678 1234 5678</p>
          </div>
          <div className="card-bottom">
            <p className="card-holder">Olga R. Holland</p>
            <p className="valid-through">VALID THRU 04/25</p>
          </div>
        </div>
      </div> */}
      {/* <div className="store-container"> */}
      {/* <p className="setting-title">Upcoming Invoice</p> */}
      {/* <div className="data-table-container">
          <table className="data-table custom-table rounded-table">
            <thead className="table-header">
              <tr>
                <th scope="col">No</th>
                <th scope="col">Date</th>
                <th scope="col">Description</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>
            <tbody className="table-body">
              <tr>
                <td>1</td>
                <td>2024-09-26</td>
                <td>Sample description text here.</td>
                <td>$100.00</td>
              </tr>
              <tr>
                <td>2</td>
                <td>2024-09-25</td>
                <td>Another description text here.</td>
                <td>$150.00</td>
              </tr>
            </tbody>
            <tfoot></tfoot>
          </table>
        </div> */}
      {/* <DataTable data={subscriptionEntryData} columns={columns} showAction={false} /> */}
      {/* </div> */}
      <div className="store-container">
        <p className="setting-title">Invoices</p>
        <DataTable
          data={subscriptionEntryData}
          columns={invoices}
          showAction={false}
          showFooter={true}
        />
      </div>
    </>
  );
}
export default StoreInformation;
