/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import SettingTable from "../ManageSettings/SettingTable";
import Swal from "sweetalert2";

function CashDrop() {
  const [selectedShift, setSelectedShift] = useState("1st Shift");
  const [cashDrop, setCashdrop] = useState(""); // Initialize as an empty string
  const [shift, setShift] = useState(null); // Correctly initialize shift as null
  const [cashDropHistory, setCashDropHistory] = useState([]); // Store previous cash drops
  const [editing, setEditing] = useState(false); // To track if we're editing
  const [editData, setEditData] = useState(null); // Data for the entry being edited
  const [newAmount, setNewAmount] = useState(""); // The new amount during editing

  const { Get, Post, Delete, Patch } = useApi();
  const { user } = useContext(AuthContext);

  const today = new Date().toISOString().split("T")[0];

  const handleShiftChange = (event) => {
    setSelectedShift(event.target.value);
  };

  // Get shift data and cash drop history
  const handleGet = async () => {
    try {
      // Fetch both shift data and cash drop history concurrently using Promise.all
      const [shiftResponse, cashDropResponse] = await Promise.all([
        Get("isShiftActive"),
        Get("cashDropData"),
      ]);

      if (shiftResponse && shiftResponse.shift_id) {
        setShift(shiftResponse.shift_id); // Set shift data
      } else {
        console.error("Shift data not found.");
      }

      if (cashDropResponse && Array.isArray(cashDropResponse)) {
        setCashDropHistory(cashDropResponse); // Set cash drop history
      } else {
        console.error("Cash drop history not found or not an array.");
      }
    } catch (error) {
      console.error("Error fetching shift data or cash drop history:", error);
    }
  };

  // Handle submit new cash drop
  const handleSubmit = async () => {
    if (!cashDrop || cashDrop.trim() === "") {
      alert("Please enter a valid cash drop amount.");
      return;
    }

    if (!shift) {
      alert("Shift data is not available. Please try again later.");
      return;
    }

    try {
      const response = await Post("cashDropData", {
        shift: shift, // Send the shift data
        amount: cashDrop,  // Send the amount from the state
        store: user.store, // Add the user store info
      });

      if (response && response.data) {
        setCashDropHistory(prevHistory => [
          ...prevHistory,
          response.data
        ]);
        setCashdrop(""); // Clear the input field after submission
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error submitting cash drop data:", error);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
           title: "Are you sure?",
           text: "You won't be able to revert this!",
           icon: "warning",
           showCancelButton: true,
           confirmButtonColor: "#d33",
           cancelButtonColor: "#3085d6",
           confirmButtonText: "Yes, delete it!",
       });
   
       // Check if the user clicked the confirm button
       if (!result.isConfirmed) return; // Exit if the user cancels
   {
      try {
        const response = await Delete(`cashDropData`, id);
        if (response && response.data) {
          setCashDropHistory(prevHistory =>
            prevHistory.filter(item => item.id !== id)
          );
        }
      } catch (error) {
        console.error("Error deleting cash drop:", error);
      }
    }
  };

  const handleEdit = (data) => {
    setEditing(true);
    setEditData(data); // Store the original data to update
    setNewAmount(data.amount); // Set the current amount to the input field
  };

  const handleUpdate = async () => {
    if (!newAmount || newAmount.trim() === "") {
      alert("Please enter a valid amount.");
      return;
    }

    try {
      const response = await Patch(`cashDropData`, editData.id, {
        amount: newAmount,
        shift: shift,
        store: user.store,
      });

      if (response && response.data) {
        setCashDropHistory(prevHistory =>
          prevHistory.map(item =>
            item.id === editData.id ? { ...item, amount: newAmount } : item
          )
        );
        setEditing(false);
        setEditData(null);
        setNewAmount(""); // Clear input after update
      }
    } catch (error) {
      console.error("Error updating cash drop:", error);
    }
  };

  // Only call handleGet once after component mounts
  useEffect(() => {
    handleGet(); // Get shift data and cash drop history when the component mounts
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <div className="business-header">
      <div className="header-row">
        {/* <div className="business-title">
          <div className="dropdown">
            <select
              id="shift-select"
              value={selectedShift}
              className="dropdown-toggle shift"
              onChange={handleShiftChange}
              style={{
                padding: "10px",
                marginBottom: "20px",
                fontSize: "16px",
                height: "44px",
              }}
            >
              <option value="1st" className="shift-text">1st Shift</option>
              <option value="2nd" className="shift-text">2nd Shift</option>
              <option value="3rd" className="shift-text">3rd Shift</option>
            </select>
          </div>
          <input
            className="form-control"
            type="date"
            name="reportDate"
            value={today}
            readOnly
            style={{
              backgroundColor: "#f4f4f4",
              marginLeft: "10px",
              height: "44px",
            }}
          />
        </div> */}
      </div>

      <div className="row business-report d-flex align-items-center">
        <div className="col-md-4">
          <div className="data-report">
            <label htmlFor="amount" className="label">Cash Drop</label>
            <input
              type="text"
              id="amount"
              name="amount"
              className="input"
              value={cashDrop}
              onChange={(e) => setCashdrop(e.target.value)}
              onInput={(e) => {
                // Allow only numbers and a decimal point with two digits after it
                e.target.value = e.target.value
                  .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                  .slice(0, 12); 
              }}
              style={{
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                width: "100%",
                fontSize: "16px",
              }}
            />
          </div>
        </div>
        <div className="col-md-4 d-flex justify-content-center">
          <button
            className="btn btn-primary cus"
            onClick={handleSubmit}
            style={{
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            Submit
          </button>
        </div>
      </div>

      <div className="d-flex align-items-center mt-3">
        <div className="col-md-12 mb-2">
          <SettingTable
            data={cashDropHistory}
            columns={[
              { header: "Amount", field: "amount" },
              { header: "Timestamp", field: "timestamp" },
            ]}
            showAction={true}
            showFooter={true}
            showExport={false}
            showFilter={false}
            onDelete={handleDelete}
            onEdit={handleEdit} // pass the edit function to the table
          />
        </div>
      </div>

      {/* Edit Modal */}
      {editing && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              width: "400px",
              maxWidth: "90%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <span
              onClick={() => setEditing(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                fontSize: "24px",
                cursor: "pointer",
              }}
            >
              &times;
            </span>
            <h3>Edit Cash Drop</h3>
            <div>
              <label htmlFor="edit-amount">Amount</label>
              <input
                type="text"
                id="edit-amount"
                value={newAmount}
                onChange={(e) => setNewAmount(e.target.value)}
                onInput={(e) => {
                  // Allow only numbers and a decimal point with two digits after it
                  e.target.value = e.target.value
                    .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                    .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                    .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                }}
            
                style={{
                  padding: "8px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  width: "100%",
                  fontSize: "16px",
                }}
              />
            </div>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <button
              className="btn btn-primary"
                onClick={handleUpdate}
                style={{
                  padding: "8px 16px",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                Update
              </button>
              <button
              className="btn btn-contained"
                onClick={() => setEditing(false)}
                style={{
                  padding: "8px 16px",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CashDrop;
