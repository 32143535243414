import React from "react";
import CurrentStore from "./CurrentStore/CurrentStore";
import Dashboard from "../../AppPAges/Dashboard/Dashboard";
import ExpenseBreakdown from "./CurrentStore/ExpenseBreakdown";
import InvoiceTable from "./Invoice/InvoiceTable";

function MainDashboard() {
  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
  
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
  
        <div style={{ width: "60%" }}>
          <Dashboard />
        </div>
    
        <div style={{ width: "35%" }}>
          <CurrentStore />
          <ExpenseBreakdown />
        </div>
      </div>

      
      <div
        style={{
        
         marginTop:'10px',
         padding:'10px',
         marginLeft:'155px',
          width:'84%',
          borderRadius:'10px',
         backgroundColor:'white',
          justifyContent: "center", 
          
        }}
      >
        <InvoiceTable />
      </div>
    </div>
  );
}

export default MainDashboard;
