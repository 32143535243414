/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import SettingTable from "../ManageSettings/SettingTable";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import { toast, ToastContainer } from "react-toastify";
import ReusableModal from "../ManageSettings/ReusableModal";
import AuthContext from "../../../../utils/secure-route/AuthContext";
import Swal from "sweetalert2";

function ManageGames() {
  const [games, setGames] = useState([]);
  const { user } = useContext(AuthContext);
  const storeId = user.store;
  const { Get, Post, Delete, Put } = useApi();
  const [showModal, setShowModal] = useState(false);
  const [gameName, setGameName] = useState("");
  const [gameNumber, setGameNumber] = useState("");
  const [totalTickets, setTotalTickets] = useState("");
  const [gamePrice, setGamePrice] = useState("");
  const [editingGame, setEditingGame] = useState(null);

  // Function to fetch game data
  const fetchGameData = async () => {
    try {
      const response = await Get("getMyGames");
      setGames(response.games);
    } catch (error) {
      console.error("Error fetching game data:", error);
      // toast.error("Error fetching game data.");
    }
  };

  useEffect(() => {
    fetchGameData();
  }, []);

  const handleShow = (game = null) => {
    setShowModal(true);
    if (game) {
      // If editing, set the game data
      setEditingGame(game);
      setGameName(game.game_name);
      setGameNumber(game.game_no);
      setTotalTickets(game.total_no_of_tickets);
      setGamePrice(game.value);
    } else {
      // Reset form fields for a new game
      setEditingGame(null);
      setGameName("");
      setGameNumber("");
      setTotalTickets("");
      setGamePrice("");
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const today = new Date().toISOString().split("T")[0];
    const gameData = {
      game_name: gameName,
      game_no: gameNumber,
      total_no_of_tickets: totalTickets,
      value: gamePrice,
      store: storeId,
      receive_date: today,
    };

    try {
      if (editingGame) {
        // Update existing game
        const response = await Put("lotteryGameData", editingGame.id, gameData); // Use the ID of the game to update
        setGames(
          games.map((game) =>
            game.id === editingGame.id ? response.data : game
          )
        );
        toast.success("Game updated successfully!");
      } else {
        // Add new game
        const response = await Post("lotteryGameData", gameData);
        setGames((prevGames) => [...prevGames, response.data]);
        toast.success("Game added successfully!");
      }
      handleClose(); // Close modal after submission
    } catch (error) {
      console.error("Error adding/updating game:", error);

      // Check if the error response contains the specific message
      if (error.response && error.response.data.game_no) {
        const errorMessage = error.response.data.game_no[0]; // Get the specific error message
        toast.error(errorMessage); // Display the error message in a toast
      } else {
        toast.error("Error adding/updating game."); // Generic error message
      }
    }
  };


  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user clicked the confirm button
    if (!result.isConfirmed) return; // Exit if the user cancels
    try {
      await Delete("lotteryGameData", id);
      setGames(games.filter((game) => game.id !== id));
      toast.success("Game deleted successfully!");
    } catch (error) {
      toast.error(
        "Error deleting game: " +
        (error.response ? error.response.data : error.message)
      );
    }
  };

  const columns = [
    { header: "Game No", field: "game_no" },
    { header: "Game Name", field: "game_name" },
    { header: "Total Ticket Count", field: "total_no_of_tickets" },
    { header: "Ticket Value", field: "value" },
  ];

  return (
    <>
      <div className="due-days">
        <div className="header-row">
          <div className="setting-title">Manage Games</div>
          <button className="lottery-game-add-button" onClick={() => handleShow()}>
            + Add New Game
          </button>
        </div>
        <ReusableModal
          show={showModal}
          handleClose={handleClose}
          title={editingGame ? "Edit Game" : "Add New Game"}
          width="620px"
        >
          <form onSubmit={handleSubmit}>
            <div className="form-group d-flex flex-column">
              <div className="d-flex align-items-center mb-3">
                <input
                  type="text"
                  className="input-field"
                  placeholder="Game Name"
                  value={gameName}
                  onChange={(e) => setGameName(e.target.value)}
                  style={{ width: "270px", marginRight: "10px" }}
                  required

                />
                <input
                  type="text"
                  className="input-field"
                  placeholder="Game Number"
                  value={gameNumber}
                  onChange={(e) => setGameNumber(e.target.value)}
                  style={{ width: "270px", marginRight: "10px" }}
                  onInput={(e) => {
                    // Allow only numbers and a decimal point with two digits after it
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                      .slice(0, 12);
                  }}
                  required
                />
              </div>
              <div className="d-flex align-items-center mb-3">
                <input
                  type="number"
                  className="input-field"
                  placeholder="Total Tickets"
                  value={totalTickets}
                  onChange={(e) => setTotalTickets(e.target.value)}
                  style={{ width: "270px", marginRight: "10px" }}
                  onInput={(e) => {
                    // Allow only numbers and a decimal point with two digits after it
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                      .slice(0, 12);
                  }}
                  required
                />
                <input
                  type="number"
                  className="input-field"
                  placeholder="Game Price"
                  value={gamePrice}
                  onChange={(e) => setGamePrice(e.target.value)}
                  style={{ width: "270px", marginRight: "10px" }}
                  required
                />
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  style={{
                    marginRight: "40px",
                    padding: "4px 8px",
                    backgroundColor: "#4545db",
                    color: "white",
                    border: "none",
                    borderRadius: "40px",
                    cursor: "pointer",
                    width: "124px",
                    height: "42px",
                    fontSize: "18px",
                  }}
                  type="submit"
                >
                  {editingGame ? "Update" : "Add Game"}
                </button>
              </div>
            </div>
          </form>
        </ReusableModal>
        <SettingTable
          data={games}
          columns={columns}
          showAction={true}
          showFooter={true}
          onEdit={handleShow}
          onDelete={handleDelete}
        />
      </div>
      <ToastContainer />
    </>
  );
}

export default ManageGames;
