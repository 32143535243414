import React, { useState, useEffect } from 'react';
import { MoreVert } from '@mui/icons-material';
import useApi from '../../../../../utils/api-manager/Helper/useApi' 
import StoreCashTable from './StoreCashTable';

const StoreCashBalance = () => {
  const [showTable, setShowTable] = useState(false);
  const [storeData, setStoreData] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null); 
  const [otherStoreCash, setOtherStoreCash] = useState(null); 

  const{Get} = useApi()

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  useEffect(() => {
   
    const fetchDashboardCashBalance = async () => {
      try {
        setLoading(true); 
        const response = await Get('dashboardCashBalance');
        setStoreData(response);
        setOtherStoreCash(response.additional_stores);
      } catch (err) {
        setError('Failed to fetch data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardCashBalance();
  }, []);

  return (
    <div style={{ maxWidth: '513px', height: '250px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '16px', marginTop: '20px', backgroundColor: '#fff' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h6 style={{ fontWeight: 'bold', whiteSpace: 'nowrap', fontSize: '18px' }}>
          Current Store Cash <br />
          Balance
        </h6>
        {/* <button onClick={toggleTable} style={{ fontWeight: 'bold', color: '#1976d2', background: 'none', border: 'none', textDecoration: 'none', cursor: 'pointer' }}>
          View All &raquo;
        </button> */}
      </div>

      <div>
        <div style={{
          display: 'flex', justifyContent: 'space-between', alignItems: 'center',
          backgroundColor: '#f1f3f4', borderRadius: '8px', padding: '10px', marginTop: '0px'
        }}>
          <span style={{ fontWeight: 'bold' }}>USD Amount</span>
          <button style={{ background: 'none', border: 'none', color: 'gray', cursor: 'pointer' }}>
            <MoreVert />
          </button>
        </div>

        <div style={{
          marginTop: '16px', backgroundColor: '#f1f3f4', borderRadius: '8px', padding: '10px', marginTop: '10px'
        }}>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p style={{ color: 'red' }}>{error}</p>
          ) : (
            <>
              <h6 style={{ fontWeight: 'bold', textAlign: 'left' }}>
                {storeData?.store_name || 'N/A'}
              </h6>

              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <p style={{ color: '#757575', textAlign: 'left' }}>
                  {storeData?.address || 'N/A'}
                </p>

                <h6 style={{ fontWeight: 'bold', color: 'black', textAlign: 'right', marginBottom: '20px' }}>
                  ${storeData?.total_current_cash?.toLocaleString() || '0.00'}
                </h6>
              </div>
            </>
          )}
        </div>
      </div>

      <StoreCashTable showTable={showTable} otherStoreCash={otherStoreCash} />
    </div>
  );
};

export default StoreCashBalance;
