/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import SettingTable from "../ManageSettings/SettingTable";
import useApi from "../../../../utils/api-manager/Helper/useApi";
import AuthContext from "../../.././../utils/secure-route/AuthContext";
import { toast,ToastContainer } from "react-toastify";
export default function GeneralLedgerSalesTax() {
  // const [billDate, setBillDate] = useState("2024-09-20"); // Example default date
  // const dateInputRef = useRef(null);
  const [tax, setTax] = useState([]);
  const { Post } = useApi();
  const { user } = useContext(AuthContext);
  // State for selected month and year
  const [selectedMonth, setSelectedMonth] = useState(getCurrentDateInfo("month"));
  const [selectedYear, setSelectedYear] = useState(getCurrentDateInfo("year"));
  const storeid = user.store;

  function getCurrentDateInfo(type) {
    const currentDate = new Date();

    if (type === "month") {
      const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      return monthNames[currentDate.getMonth()]; // Returns month as string
    } else if (type === "year") {
      return currentDate.getFullYear().toString(); // Returns year as string
    } else {
      return "Invalid type. Use 'month' or 'year'.";
    }
  }


  // Modify the getSalesTax function to use selected month and year
  const getSalesTax = async () => {
    try {
      const requestData = {
        months: selectedMonth, // Use the selected month
        year: selectedYear, // Use the selected year
      };

      const response = await Post("salesTax", requestData);

      if (response && response.status === 200) {
        setTax(Array.isArray(response.data) ? response.data : [response.data]);
      } else {
        // Handle error response here (optional)
      }
    } catch (err) {
      // Handle error here
      console.error("Error fetching sales tax:", err);
    }
  };

  const [showModal, setShowModal] = useState(false);
  // Fetch the sales tax data when selectedMonth or selectedYear changes
  useEffect(() => {
    getSalesTax(); // Fetch data whenever the selected month/year changes
  }, [selectedMonth, selectedYear, showModal]); // Re-fetch data when selectedMonth or selectedYear changes

  const toggleModal = () => setShowModal(!showModal);
  const columns = [
    { header: "Collected Tax", field: "collected_tax" },
    { header: "Taxable Sales", field: "taxable_sales" },
    { header: "Mon Taxable Sales", field: "mon_taxable_sales" },
    { header: "Total Net Sales", field: "total_net_sales" },
    { header: "Prepaid Tax", field: "prepaid_tax" },
    { header: "Due Tax", field: "due_tax" },
  ];

  // Array for months (used in the select dropdown)
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Array for years (you can modify this as needed)
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  useEffect(()=>{
    setSelectedMonth(getCurrentDateInfo("month"))
    setSelectedYear(getCurrentDateInfo("year"))
  },[])

  const handlePrepaidTaxSubmit = async (e) => {
    e.preventDefault(); // Prevents default page reload
    // Create FormData object directly from the event target (form element)
    const formData = new FormData(e.target);
    // Convert FormData to a plain object
    const payload = Object.fromEntries(formData.entries());
    payload.store = storeid;
    payload.month = selectedMonth.toLowerCase();
    payload.year = selectedYear;

    const prepaidTaxAmount = parseFloat(payload.prepaid_tax);
    if (isNaN(prepaidTaxAmount) || prepaidTaxAmount < 0) {
      toast.error("Please enter a valid prepaid tax amount.");
      return; 
    }
    await Post("prepaidTaxData", payload)
      .then((resp) => {
        setShowModal(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
    <ToastContainer />
    <div className="main-container mt-2">
      <div className="d-flex p-2">
        <div className="expensecontainer d-flex flex-column pd-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3 className="mb-1">Sales Tax</h3>
            <button className="btn btn-primary cus" onClick={toggleModal}>
              {" "}
              + Add Prepaid Tax
            </button>
          </div>

          <div className="d-flex mb-3" style={{ gap: "15px" }}>
            <select
              className="form-control"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              style={{
                width: "250px", // Custom width
                borderRadius: "8px", // Rounded corners
                backgroundColor: "#f0f0f0", // Grey background color
                border: "1px solid #ccc", // Border styling
                padding: "5px 10px", // Padding for better spacing inside the select
              }}
            >
              {months.map((month, index) => (
                <option key={index} value={month}>
                  {month}
                </option>
              ))}
            </select>

            <select
              className="form-control"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              style={{
                width: "250px", // Custom width
                borderRadius: "8px", // Rounded corners
                backgroundColor: "#f0f0f0", // Grey background color
                border: "1px solid #ccc", // Border styling
                padding: "5px 10px", // Padding for better spacing inside the select
              }}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>

          <SettingTable
            data={Array.isArray(tax) ? tax : []}
            columns={columns}
            showFooter={true}
            showAction={false}
            showExport={true}
            showFilter={false}
            // onEdit={handleEditBankDeposit}
            // onDelete={handleDeleteBankDeposit}
          />

          {showModal && (
            <div className="modal-overlay" onClick={toggleModal}>
              <div
                className="modal-container"
                onClick={(e) => e.stopPropagation()}
                style={{ width: "740px" }}
              >
                <div className="modal-header" style={{ border: "none" }}>
                  <h5
                    className="modal-title p-1"
                    style={{ fontWeight: "bold" }}
                  >
                    Add Prepaid Tax
                  </h5>

                  <svg
                    onClick={toggleModal}
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginRight: "20px",
                      cursor: "pointer",
                    }}
                  >
                    <path
                      d="M1 1L13 13M13 1L1 13"
                      stroke="black"
                      stroke-linecap="round"
                    />
                  </svg>

                  {/* <button type="button" className="modal-close-btn"
                    style={{ border: "none", backgroundColor: "transparent", fontSize:'32px' }}
                  onClick={toggleModal}>
                    <span className='closebtn'>&times;</span>
                  </button> */}
                </div>
                <div className="modal-body">
                  <form onSubmit={handlePrepaidTaxSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <select
                          className="form-control"
                          value={selectedMonth}
                          onChange={(e) => setSelectedMonth(e.target.value)}
                          style={{
                            width: "330px", // Custom width

                            border: "1px solid #ccc", // Border styling
                            padding: "5px 10px",
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px", // Padding for better spacing inside the select
                          }}
                        >
                          {months.map((month, index) => (
                            <option key={index} value={month}>
                              {month}
                            </option>
                          ))}
                        </select>
                      </div>

                      <select
                        className="form-control"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        style={{
                          width: "330px", // Custom width

                          border: "1px solid #ccc", // Border styling
                          padding: "5px 10px",
                          backgroundColor: "#0023000C",
                          borderRadius: "10px",
                          height: "52px", // Padding for better spacing inside the select
                        }}
                      >
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-row d-flex col-md-6">
                      <div class="containercolor">
                        <div
                          className="input-group col-md-6  p-2 mt-4"
                          style={{
                            width: "330px",
                            backgroundColor: "#0023000C",
                            borderRadius: "10px",
                            height: "52px",
                            border: "1px lightgray solid",
                          }}
                        >
                          <div
                            className="input-group-prepend"
                            style={{
                              background: "#F5F5F5",
                              height: "100%",
                              borderRadius: "10px 0 0 10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span
                              className="input-group-text prefixtext"
                              style={{
                                border: "none",
                                fontSize: "14px",
                              }}
                            >
                              USD
                            </span>
                          </div>
                          <input
                            type="text"
                            class="form-control sampletext"
                            placeholder="Amount"
                            aria-label="Username"
                            name="prepaid_amount"
                            aria-describedby="addon-wrapping"
                            onInput={(e) => {
                              // Allow only numbers and a decimal point with two digits after it
                              e.target.value = e.target.value
                                .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                                .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                                .slice(0, 12); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                            }}
                          />
                        </div>
                      </div>


                    </div>

                    <div className="modal-footer" style={{ border: "none" }}>
                      <button
                        type="button"
                        className="btn btn-secondary-outline"
                        onClick={toggleModal}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    </>
  );
}
