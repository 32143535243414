/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Invoice.css";
import AuthContext from "../../../../../utils/secure-route/AuthContext";
import TableComponent from "./PayInvoice_Table";
import useApi from "../../../../../utils/api-manager/Helper/useApi";
import { toast, ToastContainer } from "react-toastify";
import AddVendorModal from "../../ReusableForm/AddVendorModal";

function PayInvoice() {
  const { Get, Patch } = useApi();
  const [reloadData, setReloadData] = useState(false);
  let { user } = useContext(AuthContext);
  const today = new Date().toISOString().split("T")[0]; // Formats date as YYYY-MM-DD
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const handleVendorAdded = (newVendor) => {
    setVendors((prevVendors) => [...prevVendors, newVendor]); // Add new vendor to the list
    setFormData({ ...formData, vendor_department: newVendor.id }); // Set the newly added vendor as the selected one
  };

  const [formData, setFormData] = useState({
    store_id: user.store,
    invoice_no: "",
    date: "",
    status: "paid",
    pay_method_status: "pay_now",
    pay_method: "",
    amount: "",
    remaining_amount: "", // Initialize as empty string or 0
    discount: 0,
    pay_amount: 0,
    after_discount: 0,
    prepaid_tax: true,
    vendor_department: "",
    vendor_department_name: "",
    cheque_no: null,
    note: "",
    due_date: null,
    bank: "",
  });

  const makeBlank = ()=>{
    setFormData({
      store_id: user.store,
      invoice_no: "",
      date: "",
      status: "paid",
      pay_method_status: "pay_now",
      pay_method: "",
      amount: "",
      remaining_amount: "", // Initialize as empty string or 0
      discount: 0,
      pay_amount: 0,
      after_discount: 0,
      prepaid_tax: true,
      vendor_department: "",
      vendor_department_name: "",
      cheque_no: null,
      note: "",
      due_date: null,
      bank: "",
    });
  }
  const [vendors, setVendors] = useState([]);
  const [banks, setBanks] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [initialAmount, setInitialAmount] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [vendorResponse, bankResponse, invoiceResponse] =
          await Promise.all([
            Get("vendorDepartmentData"),
            Get("bank"),
            Get("invoiceData"),
          ]);

        setVendors(vendorResponse);
        setBanks(bankResponse);
        setFilteredInvoices(invoiceResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [reloadData]);

  useEffect(() => {
    if (selectedVendor) {
      setFilteredInvoices((prevInvoices) =>
        prevInvoices.filter(
          (invoice) => invoice.vendor_department === selectedVendor
        )
      );
    }
  }, [selectedVendor]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = parseFloat(value) || 0;

    if (name === "vendor_department") {
      setSelectedVendor(value);
      setFormData((prevData) => ({
        ...prevData,
        vendor_department: value,
        invoice_no: "",
        amount: "",
        pay_amount: 0,
        discount: 0,
        after_discount: 0,
      }));
    } else if (name === "discount") {
      setFormData((prevData) => {
        const newDiscount = parsedValue;
        const newPayAmount =
          prevData.pay_amount || prevData.remaining_amount || 0; // Use pay_amount or remaining_amount
        const newAfterDiscount = Math.max(0, newPayAmount - newDiscount); // Calculate after_discount

        return {
          ...prevData,
          discount: newDiscount,
          after_discount: newAfterDiscount, // Update after_discount when discount changes
        };
      });
    } else if (name === "pay_amount") {
      setFormData((prevData) => {
        const newPayAmount = parsedValue;

        const newAfterDiscount = Math.max(0, newPayAmount - prevData.discount); // Recalculate after_discount

        return {
          ...prevData,
          pay_amount: newPayAmount,
          after_discount: newAfterDiscount,
        };
      });
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleInvoiceSelect = (invoice) => {
    if (invoice) {
      // Populate form data with the selected invoice details
      setFormData((prevData) => ({
        ...prevData,
        id: invoice.id,
        invoice_no: invoice.invoice_no,
        amount: invoice.amount,
        remaining_amount: invoice.remaining_amount || invoice.amount, // Set to invoice's remaining amount
        pay_amount: invoice.remaining_amount || invoice.amount,
        discount: 0,
        after_discount: invoice.remaining_amount || invoice.amount,
        vendor_department: invoice.vendor_department,
        vendor_department_name: invoice.vendor_department_name,
        date: invoice.date || "",
      }));
      setInitialAmount(invoice.amount);
    } else {
      // Clear the form state
      setFormData({
        id: "",
        invoice_no: "",
        amount: "",
        remaining_amount: "",
        pay_amount: "",
        discount: 0,
        after_discount: "",
        vendor_department: "",
        vendor_department_name: "",
        date: "",
      });
      setInitialAmount("");
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the payment method is selected
    if (!formData.pay_method) {
        toast.error("Please select a payment method before submitting.");
        return; // Prevent form submission if payment method is not selected
    }

    const payAmount = parseFloat(formData.pay_amount);
    const previousRemainingAmount = parseFloat(formData.remaining_amount) || 0;


    const newRemainingAmount = previousRemainingAmount - payAmount;


    if (newRemainingAmount < 0) {
        toast.error("Payment amount exceeds the remaining amount. Please enter a valid amount.");
        return; // Prevent form submission if payment exceeds remaining amount
    }

    const status = newRemainingAmount <= 0 ? "paid" : "partially_paid"; // If remaining is 0 or less, mark it as 'paid'
    const finalRemainingAmount =
        newRemainingAmount <= 0 ? "0.00" : newRemainingAmount.toFixed(2); // If fully paid, remaining should be 0

    let updatedFormData = {
        ...formData,
        remaining_amount: finalRemainingAmount, // Update the remaining amount
        status: status, // Set the status based on the remaining amount
        cheque_no: formData.pay_method === "cheque" ? formData.cheque_no : null,
        bank: formData.pay_method === "bank" ? formData.bank : null,
    };

    // Check if the pay_method_status is 'credit_invoice'
    if (updatedFormData.pay_method_status !== "credit_invoice") {
        updatedFormData = {
            ...updatedFormData,
            pay_method_status: "credit_invoice", // Force setting pay_method_status to 'credit_invoice'
        };
    }

    console.log("Submitting:", updatedFormData);

    try {
        if (!updatedFormData.id) {
            toast.error("No invoice selected. Please select an invoice before submitting.");
            return;
        }

        const response = await Patch(`invoiceData`, updatedFormData.id, updatedFormData);

        if (response && response.status === 200) {
            console.log("Submission successful:", response.data);
            toast.success("Invoice payment submitted successfully!");
            setReloadData(!reloadData);
            makeBlank();
            // window.location.reload(); // Reload the page to reflect changes
        } else {
          setReloadData(!reloadData);

            console.error("Failed to submit:", response.data);
            toast.error(`Error: ${response.data.message || "Failed to update invoice."}`);
        }
    } catch (error) {
      setReloadData(!reloadData);
        console.error("Error:", error);
        toast.error(`An unexpected error occurred: ${error.message || "Please try again later."}`);
    }
};

  const handleCancel = () => {
    navigate("/");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (path) => {
    setIsOpen(false); // Close the dropdown after clicking a link
    navigate(path); // Navigate to the clicked path
  };
  const grandTotal = formData.after_discount || formData.pay_amount || 0;
  // const grandTotal = formData.remaining_amount > 0
  // ? formData.remaining_amount
  // : formData.after_discount || formData.pay_amount || 0;

  return (
    <div className="dashboard-container">
      <div className="formcontainer">
        <div
          className="container"
          style={{
            backgroundColor: "white",
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
          }}
        >
          <form onSubmit={handleSubmit}>
            <div className="d-flex justify-content-between mb-4">
              <div className="dropdown" ref={dropdownRef}>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  onClick={toggleDropdown}
                >
                  Pay Invoice
                  <svg
                    width="16"
                    height="10"
                    viewBox="0 0 16 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: "10px" }}
                  >
                    <path
                      d="M8.07608 9.20648C7.97615 9.20775 7.87708 9.18722 7.78523 9.14622C7.69338 9.10521 7.61078 9.04463 7.54275 8.96839L0.685603 1.82553C0.380841 1.50807 0.380841 1.016 0.685603 0.698544C0.990365 0.381084 1.46275 0.381084 1.76751 0.698544L8.09132 7.28585L14.3999 0.714417C14.7046 0.396957 15.177 0.396957 15.4818 0.714417C15.7866 1.03188 15.7866 1.52394 15.4818 1.8414L8.62465 8.98426C8.47227 9.14299 8.27417 9.22235 8.09132 9.22235L8.07608 9.20648Z"
                      fill="#002300"
                    />
                  </svg>
                </button>
                {isOpen && (
                  <ul
                    className="dash-drop-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li>
                      <a
                        className="dash-drop-menu-item"
                        onClick={() => handleLinkClick("/")}
                      >
                        Add Invoice
                      </a>
                    </li>
                    <li>
                      <a
                        className="dash-drop-menu-item"
                        onClick={() => handleLinkClick("/payInvoice")}
                      >
                        Pay Invoice
                      </a>
                    </li>
                    <li>
                      <a
                        className="dash-drop-menu-item"
                        onClick={() => handleLinkClick("/bankDeposit")}
                      >
                        Bank Deposit
                      </a>
                    </li>
                    <li>
                      <a
                        className="dash-drop-menu-item"
                        onClick={() => handleLinkClick("/atmDeposit")}
                      >
                        ATM Deposit
                      </a>
                    </li>
                  </ul>
                )}
              </div>
              <div>
                <button
                  type="button"
                  className="btn btn-contained me-2"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </div>
            <div className="col-md-12 row">
              <div className="mb-3 col-md-3">
                <input
                  type="date"
                  className="form-control-borderless"
                  name="date"
                  value={formData.date || today}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3 col-md-3">
                <select
                  className="form-control-borderless"
                  name="vendor_department"
                  value={formData.vendor_department}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    if (selectedValue === "add-vendor") {
                      handleShow(); // Modal ko open karne ke liye function
                      setFormData((prevData) => ({
                        ...prevData,
                        vendor_department: "", // Field ko reset karne ke liye
                      }));
                    } else {
                      handleChange(e); // Existing handler for normal options
                    }
                  }}
                >
                  <option value="">Select Vendor</option>
                  <option value="add-vendor">Add Vendor</option>{" "}
                  {/* Add Vendor Option */}
                  {vendors
                    .filter((vendor) => !vendor.is_deleted) // Exclude deleted vendors
                    .map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="mb-3 col-md-3">
                <input
                  name="invoice_no"
                  type="text"
                  className="form-control-borderless"
                  value={formData.invoice_no}
                  placeholder="Invoice No"
                  onChange={handleChange}
                />
                {/* <select
                  className="form-control-borderless"
                  onChange={(e) =>
                    handleInvoiceSelect(
                      filteredInvoices.find((inv) => inv.id === e.target.value)
                    )
                  }
                >
                  <option value="">Select Invoice</option>
                  {filteredInvoices.map((invoice) => (
                    <option key={invoice.id} value={invoice.id}>
                      {invoice.invoice_no}
                    </option>
                  ))}
                </select> */}
              </div>
            </div>
            <div
              className="mb-3 d-flex align-items-center"
              style={{ paddingLeft: "10px" }}
            >
              <span className="me-4">Payment Method:</span>
              <div className="form-check me-3">
                <input
                  type="radio"
                  className="form-check-input"
                  name="pay_method"
                  value="cash"
                  checked={formData.pay_method === "cash"}
                  onChange={handleChange}
                />
                <label className="form-check-label">Cash</label>
              </div>
              <div className="form-check me-3">
                <input
                  type="radio"
                  className="form-check-input"
                  name="pay_method"
                  value="cheque"
                  checked={formData.pay_method === "cheque"}
                  onChange={handleChange}
                />
                <label className="form-check-label">Cheque</label>
              </div>
              <div className="form-check">
                <input
                  required
                  type="radio"
                  className="form-check-input"
                  name="pay_method"
                  value="bank"
                  checked={formData.pay_method === "bank"}
                  onChange={handleChange}
                />
                <label className="form-check-label">Bank Card (ACH/EFT)</label>
              </div>
            </div>

            <div className="mb-3 col-md-12 row">
              <div className="col-md-2">
                <input
                  name="amount"
                  type="text"
                  className="form-control-borderless"
                  value={formData.amount}
                  readOnly
                  placeholder="Amount"
                />
              </div>
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control-borderless"
                  name="pay_amount"
                  maxLength={10}
                  placeholder="Pay Amount"
                  value={formData.pay_amount || ""}
                  onChange={handleChange}
                  onInput={(e) => {
                    // Allow only numbers and a decimal point with two digits after it
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                      .replace(/^(\d*\.?\d{0,2}).*/g, '$1') // Allow only two decimal places
                      .slice(0, 10); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                  }}
                />
              </div>
              <div className="col-md-2">
                <input
                  type="number"
                  className="form-control-borderless "
                  name="discount"
                  value={formData.discount || ""}
                  onChange={handleChange}
                  placeholder="Enter Discount"
                  onInput={(e) => {
                    // Allow only numbers and a decimal point with two digits after it
                    e.target.value = e.target.value
                      .replace(/[^0-9.]/g, '') // Remove non-numeric characters except the decimal point
                      .slice(0, 7); // Limit input length (10 digits + 1 decimal + 2 decimal places)
                  }}
                />
                  
            
              </div>
              <div className="mb-3 col-md-12 row mt-3">
              <div className="col-md-3 mt-4">
                <input
                  name="after_discount"
                  type="text"
                  maxLength={2}
                  className="form-control"
                  value={formData.after_discount || ""}
                  placeholder="After Discount Amount"
                  readOnly
                />
              </div>

              <div className="col-md-3">
                <label>Grand Total:</label>
                <input
                  type="text"
                  className="form-control"
                  value={grandTotal || ""}
                  readOnly
                />
              </div>
              </div>
            </div>
            <div className="col-md-12">
              {formData.pay_method === "cheque" && (
                <div className="mb-3">
                  <div className="col-md-12 row">
                    <div className="mb-3 col-md-6">
                      <select
                        required
                        className="form-control-borderless"
                        name="bank"
                        value={formData.bank}
                        onChange={handleChange}
                      >
                        <option value="">Select Bank</option>
                        {banks.map((bank) => (
                          <option key={bank.id} value={bank.id}>
                            {bank.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3 col-md-6">
                      <input
                        required
                        type="text"
                        className="form-control-borderless"
                        name="cheque_no"
                        placeholder="Check Number"
                        value={formData.cheque_no}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}

              {formData.pay_method === "bank" && (
                <div className="mb-3">
                  <div className="col-md-12 row">
                    <div className="mb-3 col-md-6">
                      <select
                        required
                        className="form-control-borderless"
                        name="bank"
                        value={formData.bank}
                        onChange={handleChange}
                      >
                        <option value="">Select Bank</option>
                        {banks.map((bank) => (
                          <option key={bank.id} value={bank.id}>
                            {bank.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}

              {formData.pay_method === "Bank Card (ACH/EFT)" && (
                <div className="mb-3">
                  <div className="col-md-12 row">
                    <div className="mb-3 col-md-6">
                      <select
                        className="form-control-borderless"
                        name="bank"
                        value={formData.bank}
                        onChange={handleChange}
                      >
                        <option value="">Select Bank</option>
                        {banks.map((bank) => (
                          <option key={bank.id} value={bank.id}>
                            {bank.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div
        className="container mt-5"
        style={{
          backgroundColor: "white",
          boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
        }}
      >
        <TableComponent reloadData={reloadData} onEdit={handleInvoiceSelect} />
<ToastContainer/>
        <AddVendorModal
          show={showModal}
          handleClose={handleClose}
          onVendorAdded={handleVendorAdded}
        />
      </div>
    </div>
  );
}

export default PayInvoice;
