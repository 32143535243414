import React from 'react';

export const FilterIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.0707 1.83301H11.2291C11.9074 1.83301 12.4665 2.39218 12.4665 3.07051V4.42716C12.4665 4.92216 12.1549 5.53635 11.8524 5.84801L9.19408 8.19468C8.82741 8.50634 8.57987 9.1205 8.57987 9.6155V12.2739C8.57987 12.6405 8.33239 13.1355 8.02072 13.328L7.15905 13.8872C6.35238 14.3822 5.24319 13.823 5.24319 12.833V9.5605C5.24319 9.12967 4.99571 8.57052 4.74821 8.25885L2.40155 5.78384C2.08988 5.47217 1.8424 4.92217 1.8424 4.54633V3.12551C1.83323 2.39218 2.39236 1.83301 3.0707 1.83301Z" stroke="#002300" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.8335 11.0004V13.7504C1.8335 18.3337 3.66683 20.1671 8.25016 20.1671H13.7502C18.3335 20.1671 20.1668 18.3337 20.1668 13.7504V8.25039C20.1668 5.39039 19.4518 3.59371 17.7926 2.65871C17.3251 2.39288 16.3902 2.19121 15.5377 2.05371" stroke="#002300" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.9165 11.917H16.4998" stroke="#002300" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.0835 15.583H16.5002" stroke="#002300" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CalendarFromIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.33333 1.8335V4.5835M14.6667 1.8335V4.5835M3.20833 8.33266H18.7917M19.25 7.79183V15.5835C19.25 18.3335 17.875 20.1668 14.6667 20.1668H7.33333C4.125 20.1668 2.75 18.3335 2.75 15.5835V7.79183C2.75 5.04183 4.125 3.2085 7.33333 3.2085H14.6667C17.875 3.2085 19.25 5.04183 19.25 7.79183Z" stroke="#002300" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.9954 12.5581H11.0045M7.60278 12.5581H7.61195M7.60278 15.3081H7.61195" stroke="#002300" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const CalendarToIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.33333 1.8335V4.5835M14.6667 1.8335V4.5835M3.20833 8.33266H18.7917M19.25 7.79183V15.5835C19.25 18.3335 17.875 20.1668 14.6667 20.1668H7.33333C4.125 20.1668 2.75 18.3335 2.75 15.5835V7.79183C2.75 5.04183 4.125 3.2085 7.33333 3.2085H14.6667C17.875 3.2085 19.25 5.04183 19.25 7.79183Z" stroke="#002300" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.9954 12.5581H11.0045M7.60278 12.5581H7.61195M7.60278 15.3081H7.61195" stroke="#002300" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const DropdownArrowIcon = () => (
  <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path d="M8.07608 9.74603C7.97615 9.7473 7.87708 9.72677 7.78523 9.68577C7.69338 9.64476 7.61078 9.58419 7.54275 9.50794L0.685603 2.36508C0.380841 2.04762 0.380841 1.55556 0.685603 1.2381C0.990365 0.920635 1.46275 0.920635 1.76751 1.2381L8.09132 7.8254L14.3999 1.25397C14.7046 0.936508 15.177 0.936508 15.4818 1.25397C15.7866 1.57143 15.7866 2.06349 15.4818 2.38095L8.62465 9.52381C8.47227 9.68254 8.27417 9.7619 8.09132 9.7619L8.07608 9.74603Z" fill="#002300" />
    </g>
  </svg>
);


export const Email = () => (
  <svg
    className="icon-btn-report"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.167 17.0807H5.83366C3.33366 17.0807 1.66699 15.8307 1.66699 12.9141V7.08073C1.66699 4.16406 3.33366 2.91406 5.83366 2.91406H14.167C16.667 2.91406 18.3337 4.16406 18.3337 7.08073V12.9141C18.3337 15.8307 16.667 17.0807 14.167 17.0807Z"
      stroke="#002300"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1663 7.5L11.558 9.58333C10.6997 10.2667 9.29134 10.2667 8.433 9.58333L5.83301 7.5"
      stroke="#002300"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export const DeleteBlack = () => (
  <svg
    className="icon-btn-report"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 4.98307C14.725 4.70807 11.9333 4.56641 9.15 4.56641C7.5 4.56641 5.85 4.64974 4.2 4.81641L2.5 4.98307"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.08301 4.13906L7.26634 3.0474C7.39967 2.25573 7.49967 1.66406 8.90801 1.66406H11.0913C12.4997 1.66406 12.608 2.28906 12.733 3.05573L12.9163 4.13906"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7082 7.61719L15.1665 16.0089C15.0748 17.3172 14.9998 18.3339 12.6748 18.3339H7.32484C4.99984 18.3339 4.92484 17.3172 4.83317 16.0089L4.2915 7.61719"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6084 13.75H11.3834"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91699 10.4141H12.0837"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);


export const Delete = () => (
  <svg
    className="icon-btn-report"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 4.98307C14.725 4.70807 11.9333 4.56641 9.15 4.56641C7.5 4.56641 5.85 4.64974 4.2 4.81641L2.5 4.98307"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.08301 4.13906L7.26634 3.0474C7.39967 2.25573 7.49967 1.66406 8.90801 1.66406H11.0913C12.4997 1.66406 12.608 2.28906 12.733 3.05573L12.9163 4.13906"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7082 7.61719L15.1665 16.0089C15.0748 17.3172 14.9998 18.3339 12.6748 18.3339H7.32484C4.99984 18.3339 4.92484 17.3172 4.83317 16.0089L4.2915 7.61719"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.6084 13.75H11.3834"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.91699 10.4141H12.0837"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const LottoIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9999 7.99805H7.99988V11.998H11.9999V7.99805Z"
      stroke="#002300"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.49999 15.002C7.32499 15.002 7.99998 14.327 7.99998 13.502V12.002H6.49999C5.67499 12.002 5 12.677 5 13.502C5 14.327 5.67499 15.002 6.49999 15.002Z"
      stroke="#002300"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.49999 8H7.99998V6.49999C7.99998 5.67499 7.32499 5 6.49999 5C5.67499 5 5 5.67499 5 6.49999C5 7.32499 5.67499 8 6.49999 8Z"
      stroke="#002300"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0001 8H13.5001C14.3251 8 15.0001 7.32499 15.0001 6.49999C15.0001 5.67499 14.3251 5 13.5001 5C12.6751 5 12.0001 5.67499 12.0001 6.49999V8Z"
      stroke="#002300"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5001 15.002C14.3251 15.002 15.0001 14.327 15.0001 13.502C15.0001 12.677 14.3251 12.002 13.5001 12.002H12.0001V13.502C12.0001 14.327 12.6751 15.002 13.5001 15.002Z"
      stroke="#002300"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.49984 18.3307H12.4998C16.6665 18.3307 18.3332 16.6641 18.3332 12.4974V7.49739C18.3332 3.33073 16.6665 1.66406 12.4998 1.66406H7.49984C3.33317 1.66406 1.6665 3.33073 1.6665 7.49739V12.4974C1.6665 16.6641 3.33317 18.3307 7.49984 18.3307Z"
      stroke="#002300"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const DropdownIcon = () => (
  <svg
    style={{ marginRight: "8px" }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
      stroke="#002300"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.71 15.1798L12.61 13.3298C12.07 13.0098 11.63 12.2398 11.63 11.6098V7.50977"
      stroke="#002300"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ReportIcon = () => (
  <svg
    className="icon-btn-report"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.04199 5.83073H13.9587V4.16406C13.9587 2.4974 13.3337 1.66406 11.4587 1.66406H8.54199C6.66699 1.66406 6.04199 2.4974 6.04199 4.16406V5.83073Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3337 12.5V15.8333C13.3337 17.5 12.5003 18.3333 10.8337 18.3333H9.16699C7.50033 18.3333 6.66699 17.5 6.66699 15.8333V12.5H13.3337Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 8.33594V12.5026C17.5 14.1693 16.6667 15.0026 15 15.0026H13.3333V12.5026H6.66667V15.0026H5C3.33333 15.0026 2.5 14.1693 2.5 12.5026V8.33594C2.5 6.66927 3.33333 5.83594 5 5.83594H15C16.6667 5.83594 17.5 6.66927 17.5 8.33594Z"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.1663 12.5H13.158H5.83301"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83301 9.16406H8.33301"
      stroke="#292D32"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon = () =>(
  <svg
  className="search-container-icon"
  width="22"
  height="22"
  viewBox="0 0 22 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.5416 19.2497C15.3511 19.2497 19.2499 15.3508 19.2499 10.5413C19.2499 5.73186 15.3511 1.83301 10.5416 1.83301C5.73211 1.83301 1.83325 5.73186 1.83325 10.5413C1.83325 15.3508 5.73211 19.2497 10.5416 19.2497Z"
    stroke="#292D32"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M20.1666 20.1663L18.3333 18.333"
    stroke="#292D32"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>
)

export const PrevIcon = () =>(
<svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 0L1.74846e-07 4L4 8L4 0Z" fill="#002300"/>
</svg>
)


export const NextIcon = () => (

  <svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 8L4 4L-3.49691e-07 0L0 8Z" fill="#002300"/>
</svg>

)


export const Clock = () => (


  <svg
  style={{ marginRight: "8px" }}
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
    stroke="#002300"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
  <path
    d="M15.71 15.1798L12.61 13.3298C12.07 13.0098 11.63 12.2398 11.63 11.6098V7.50977"
    stroke="#002300"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>
)



export const FilterButton = () => (

  <svg
  width="22"
  height="22"
  viewBox="0 0 22 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.0707 1.83301H11.2291C11.9074 1.83301 12.4665 2.39218 12.4665 3.07051V4.42716C12.4665 4.92216 12.1549 5.53635 11.8524 5.84801L9.19408 8.19468C8.82741 8.50634 8.57987 9.1205 8.57987 9.6155V12.2739C8.57987 12.6405 8.33239 13.1355 8.02072 13.328L7.15905 13.8872C6.35238 14.3822 5.24319 13.823 5.24319 12.833V9.5605C5.24319 9.12967 4.99571 8.57052 4.74821 8.25885L2.40155 5.78384C2.08988 5.47217 1.8424 4.92217 1.8424 4.54633V3.12551C1.83323 2.39218 2.39236 1.83301 3.0707 1.83301Z"
    stroke="#002300"
    strokeWidth="1.5"
    strokeMiterlimit="10"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M1.8335 11.0004V13.7504C1.8335 18.3337 3.66683 20.1671 8.25016 20.1671H13.7502C18.3335 20.1671 20.1668 18.3337 20.1668 13.7504V8.25039C20.1668 5.39039 19.4518 3.59371 17.7926 2.65871C17.3251 2.39288 16.3902 2.19121 15.5377 2.05371"
    stroke="#002300"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M11.9165 11.917H16.4998"
    stroke="#002300"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M10.0835 15.583H16.5002"
    stroke="#002300"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>

)
export const ErrorSvg = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    width="50"
    height="50"
  >
    <rect
      x="15.673"
      y="39.278"
      width="480.653"
      height="433.445"
      style={{ fill: "#C9E8FA" }}
    />
    <path
      d="M0,23.604v464.791h512V23.604H0z M480.653,457.049H31.347V54.951h449.306V457.049z"
      style={{ fill: "#67BFFF" }}
    />
    <rect
      x="256"
      y="39.278"
      width="240.327"
      height="433.445"
      style={{ fill: "#B6E0F7" }}
    />
    <polygon
      points="512,23.604 512,488.396 256,488.396 256,457.049 480.653,457.049 480.653,54.951 256,54.951 256,23.604"
      style={{ fill: "#0088FF" }}
    />
    <rect
      y="23.604"
      width="512"
      height="87.761"
      style={{ fill: "#C9AD91" }}
    />
    <rect
      x="256"
      y="23.604"
      width="256"
      height="87.761"
      style={{ fill: "#B38A65" }}
    />
    <rect
      x="397.646"
      y="23.604"
      width="114.354"
      height="87.761"
      style={{ fill: "#8A6746" }}
    />
    <g>
      <rect
        x="421.794"
        y="59.037"
        width="13.932"
        height="13.932"
        style={{ fill: "#FFFFFF" }}
      />
      <rect
        x="448.899"
        y="59.037"
        width="13.932"
        height="13.932"
        style={{ fill: "#FFFFFF" }}
      />
      <rect
        x="476.014"
        y="59.037"
        width="13.932"
        height="13.932"
        style={{ fill: "#FFFFFF" }}
      />
    </g>
    <path
      d="M362.163,283.967c0,58.211-47.188,105.399-105.399,105.399c-0.251,0-0.502,0-0.752-0.01
	c-28.473-0.188-54.251-11.682-73.101-30.208c-16.927-16.635-28.264-38.933-30.929-63.843c-0.397-3.72-0.616-7.502-0.616-11.337
	c0-31.587,13.908-59.925,35.913-79.245c0.24-0.209,0.481-0.418,0.721-0.616c8.579-7.398,18.369-13.427,29.017-17.774
	c12.048-4.901,25.203-7.659,38.996-7.743c0.251-0.01,0.502-0.01,0.752-0.01C314.974,178.578,362.163,225.755,362.163,283.967z"
      style={{ fill: "#FD7052" }}
    />
    <path
      d="M362.163,283.967c0,58.211-47.188,105.399-105.399,105.399c-0.251,0-0.502,0-0.752-0.01V178.589
	c0.251-0.01,0.502-0.01,0.752-0.01C314.974,178.578,362.163,225.755,362.163,283.967z"
      style={{ fill: "#FF4F18" }}
    />
    <polygon
      points="278.179,284.996 306.318,313.125 284.145,335.297 256.017,307.158 226.415,336.76 
	204.252,314.587 233.844,284.996 205.715,256.857 227.878,234.695 256.006,262.813 256.017,262.823 285.608,233.232 307.77,255.404"
      style={{ fill: "#FFFFFF" }}
    />
    <polygon
      points="278.179,284.996 306.318,313.125 284.145,335.297 256.017,307.158 256.006,307.169 
	256.006,262.813 256.017,262.823 285.608,233.232 307.77,255.404"
      style={{ fill: "#D9D8DB" }}
    />
  </svg>
);

